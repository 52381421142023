.dashboard {
  display: flex;
  height: 100vh;
  background-color: #ffffff;
  transition: background-color 0.3s ease;
}

.dashboard-left-navigator {
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  position: absolute;
  z-index: 5000;
  height: 96%;
  overflow: auto;
  margin-left: 0px;
  width: 0px;
  border-radius: 16px;
  background-color: #fff;
  align-self: center;
  box-shadow: 0px 12px 26px 2px rgba(112, 144, 176, 0.11);
  transition: width 0.2s ease-in-out;
  visibility: hidden;
}

.dashboard-left-navigator.menu-open {
  width: 280px;
  visibility: visible;
  margin-left: 16px;
}

@media (min-width: 1024px) {
  .dashboard-left-navigator {
    position: relative;
    visibility: hidden;
    width: 0px;
    margin-left: 16px;
    transition: width 0.2s ease-in-out;
  }

  .dashboard-left-navigator.menu-open {
    width: 280px;
    visibility: visible;
    margin-left: 16px;
  }
}

.dashboard-left-header {
  width: 100%;
  height: fit-content;
  text-align: center;
  display: grid;
  place-content: center;
  padding-block: 32px;
  border-bottom: 1px solid #e9edf7;
}

.dashboard-left-header-content {
  width: 80%;
  min-width: fit-content;
}

.dashboard-left-header h2 {
  font-family: "DM Sans", sans-serif;
  font-size: 20px;
  margin: 0;
  font-weight: 800;
  color: #1b2559;
  white-space: nowrap;
}

.dashboard-left-header h3 {
  font-family: "DM Sans", sans-serif;
  font-size: 18px;
  margin: 0;
  font-weight: 500;
  color: #1b2559;
  white-space: nowrap;
}

.dashboard-left-header .menu-current-plan {
  display: flex;
  gap: 16px;
  justify-content: space-between;
  align-items: center;
}

.dashboard-left-header .menu-current-plan .blue-line {
  width: 100%;
  flex-grow: 1;
  background-color: #3081ee;
  height: 2px;
}

.dashboard-left-navigator-content {
  display: flex;
  flex-direction: column;
  width: 88%;
  margin-inline: auto;
  max-width: 280px;
  height: 100%;
}

.small-box {
  margin-block: 16px;
  width: 100%;
  align-self: center;
  position: relative;
  box-shadow: 10px 12px 28px 2px rgba(112, 144, 176, 0.1);
  border-radius: 16px;
}

.small-box-content {
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: space-between;
  border-radius: 16px;
  padding-left: 12px;
  padding-right: 8px;
  background-color: #fff;
  border: #fff;
}

.small-box-user {
  display: flex;
  gap: 16px;
  max-width: 190px;
  align-items: center;
  flex-grow: 1;
  padding-left: 4px;
}

.small-box-user p {
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: "DM Sans", Helvetica, sans-serif;
  font-weight: 600;
  font-size: 14px;
  white-space: nowrap;
  color: #1b2559;
}

.small-box-content.night-mode-smallboxcontent .small-box-user p {
  color: white;
}

.small-box-user img {
  object-fit: cover;
  height: 24px;
  width: 24px;
  overflow: hidden;
  border-radius: 100%;
}

.small-box-user .user-img-alt-icon {
  height: 16px;
  width: 16px;
  color: #1b2559;
}

.small-box-content.night-mode-smallboxcontent .small-box-user .user-img-alt-icon {
  color: white;
}

.small-box-content .settings-icon-container {
  display: flex;
  border: 1px solid rgba(224, 229, 242, 1);
  height: fit-content;
  width: fit-content;
  padding: 6px;
  border-radius: 16px;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.1s ease-in;
}

.small-box-content .settings-icon-container:hover {
  background-color: rgba(249, 249, 249, 0.7);
}

.small-box-content.night-mode-smallboxcontent .settings-icon-container:hover {
  background-color: rgb(66, 94, 137);
}

.small-box-content .settings-icon {
  height: 14px;
  width: auto;
  color: rgba(27, 37, 89, 0.85);
}

.small-box-content .settings-icon.night-mode-icon {
  height: 14px;
  width: auto;
  color: white;
}

.dashboard-main {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  max-width: 100%;
  flex-grow: 1;
  flex-shrink: 0;
  transition: width 0.2s ease-in-out;
}

@media (min-width: 1024px) {
  .dashboard-main {
    flex-shrink: 1;
  }

  .dashboard-main.menu-open {
    width: calc(100% - 280px);
  }
}

.menu-toggle {
  position: absolute;
  height: 72px;
  width: 2rem;
  display: grid;
  place-content: center;
  top: 45%;
  left: 0;
  z-index: 500;
  transition: all 0.2s ease-in-out;
}

.menu-toggle.menu-open {
  transform: translateX(296px);
}

.menu-toggle-icon {
  z-index: 10;
  width: 16px;
  height: 16px;
  color: grey;
  transition: all 0.2s ease-in-out;
  transform: rotate(180deg);
  cursor: pointer;
}

.menu-toggle-icon.menu-open {
  transform: rotate(0deg);
}

.menu-toggle:hover .menu-toggle-icon {
  color: rgb(100, 100, 100);
}

@media (min-width: 1024px) {
  .menu-toggle.menu-open {
    transform: translateX(0px);
  }
}

.dashboard-header {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  font-family: "Plus Jakarta Sans", sans-serif;
  text-align: left;
}

.dashboard-header h2 {
  margin-left: 16px;
  font-weight: 550;
  font-size: 1.8rem;
  text-align: left;
  color: #1b2559;
  font-family: "Plus Jakarta Sans", sans-serif;
}

.chatbot-version-box {
  display: flex;
  justify-self: end;
  align-self: center;
  align-items: center;
  border-radius: 16px;
  cursor: pointer;
  gap: 16px;
  width: 100%;
  flex-shrink: 0;
  min-height: 65px;
  background: linear-gradient(15deg, #2f80ed 26.3%, #56ccf2 86.4%);
}

.chatbot-version-logo {
  margin-left: 16px;
  background-color: white;
  width: 36px;
  height: 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 100%;
}

.chatbot-version-logo img {
  width: auto;
  height: 30px;
}

.chatbot-version-box p {
  color: #fff;
  font-family: "DM Sans", "Inter", sans-serif;
  font-size: 12px;
  font-style: normal;
  margin: 0;
  font-weight: 500;
  line-height: 20px;
  max-width: 180px;
}

.chatbot-version-content > p:first-of-type {
  font-weight: 700;
  font-size: 13px;
}

.chatbot-version-content > p:last-of-type {
  font-weight: 400;
  margin-top: -2px;
}

.dashboard-chatbot {
  flex: 1;
  padding: 2px;
  margin: 8vh;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

/* Dashboard Widgets */
.dashboard-widgets {
  display: flex;
  flex-direction: column;
  margin-right: 16px;
  margin-top: 8px;
  gap: 8px;
}

/* Search Widget Bar */
.dashboard-search {
  display: flex;
  align-items: center;
  padding: 8px;
  justify-content: space-around;
  border-radius: 48px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;
}

/* Ranking Widget Bar */
.ranking {
  display: flex;
  align-items: center;
  justify-content: center;
  justify-content: space-between;
  height: 24px;
  border-radius: 50px;
  padding: 8px 15px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: background-color 0.3s ease;
}

.inside-ranking {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: fit-content;
  white-space: nowrap;
  margin-right: 5px;
  gap: 5px;
}

.ranking p {
  margin-right: 5px;
  font-family: Arial, Helvetica, sans-serif;
  font-size: small;
  font-weight: 200;
}
.ranking img {
  width: 16px;
  height: 16px;
  margin-right: 0px;
}

.slider-container {
  position: relative;
  width: 20%;
}

.slider-value {
  position: absolute;
  top: 30px;
  left: 50%;
  transform: translateX(-50%);
}

/* night mode */
.night-mode {
  background-color: #555050;
  color: #170808;
}

.night-mode1 {
  background-color: #1c3252;
  color: #f3f1f1;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
}

.night-mode-leftheader {
  color: #fff !important;
}

.night-mode-regenerate {
  background-color: #555050;
  color: white;
}

.night-mode-smallboxcontent {
  background-color: #1c3252;
  color: white;
  border: 1px solid white;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
}

.night-mode-text {
  color: white;
  font-family: "DM Sans", Helvetica, sans-serif;
}

.night-mode-icon {
  color: white;
}

.night-mode-icon:hover {
  color: rgb(222, 222, 222);
}

.night-mode-dashboard-header h2 {
  color: white;
}

.night-mode-chatcontainer {
  background-color: #555050;
}

.night-mode-chatcontainer1 .user-message p,
.night-mode-chatcontainer1 .bot-message p {
  background-color: #b62b2b;
  padding: 10px 15px;
  border-radius: 10px;
  word-wrap: break-word;
  text-align: left;
  color: #3b3b3c;
  font-family: "DM Sans" sans-serif;
}

.search_icon {
  display: flex;
  background-color: #ccc;
  border-radius: 5px;
  overflow: hidden;
  width: 60%;
}

.moon {
  transform: rotate(25deg);
  color: rgb(117, 115, 115);
}
.grey-icon {
  color: rgb(117, 115, 115);
}
.search-input {
  border: none;
  padding: 6px 12px;
  align-self: center;
  min-width: 20%;
  max-width: 150px;
  background-color: #f3f3f3;
  border-radius: 48px;
}
.icons {
  display: flex;
  align-items: center;
  margin-left: 5px;
}
.icons > * {
  margin-top: 12px;
  margin-right: 12px;
  font-size: 20px;
  height: 2.5vh;
  width: 2.5vh;
  padding: 0.1vh;
}

/* chatbot input and submit  */
.Appmob {
  height: 100%;
}

.App {
  height: 100%;
}

.main-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
}

.chat-form * {
  box-sizing: border-box;
}

.chat-form {
  display: flex;
  align-items: center;
  justify-content: center;
}

.chat-form .textarea-container {
  position: relative;
  border-radius: 24px;
  border: 1px solid #ccc;
  width: 100%;
  max-height: 200px;
  overflow: hidden;
  display: flex;
}

.chat-form textarea.night-mode-text::placeholder {
  color: rgb(225, 225, 225);
}

.chat-form textarea {
  outline: none;
  border: none;
  padding-left: 16px;
  padding-right: 32px;
  padding-top: 14px;
  padding-bottom: 12px;
  background: transparent;
  min-height: 45px;
  width: 100%;
  max-height: 190px;
  resize: none;
  font-size: clamp(0.875rem, 1vw, 1rem);
  font-family: "DM-Sans", sans-serif;
}

.chat-form textarea::placeholder {
  line-height: 16px;
}

.microphone-icon {
  position: absolute;
  width: 24px;
  height: 24px;
  right: 12px;
  top: 12px;
  cursor: pointer;
}

.microphone-icon.on {
  color: rgb(0, 123, 255);
}

.microphone-icon.off {
  color: rgba(113, 128, 150, 1);
}

.microphone.night-mode-icon .microphone-icon.off {
  color: rgb(220, 220, 220);
}

.microphone-icon.pulse {
  position: absolute;
  width: 32px;
  height: 32px;
  right: 14px;
  top: 14px;
  border-radius: 50%;
  height: 20px;
  width: 20px;

  box-shadow: 0 0 0 0 rgba(0, 123, 255, 0.5);
  transform: scale(1.2);
  animation: pulse 2s infinite;
}

@keyframes pulse {
  0% {
    /* transform: scale(1); */
    box-shadow: 0 0 0 0 rgba(0, 123, 255, 0.7);
  }

  70% {
    /* transform: scale(1); */
    box-shadow: 0 0 0 10px rgba(0, 123, 255, 0);
  }

  100% {
    /* transform: scale(1); */
    box-shadow: 0 0 0 0 rgba(0, 123, 255, 0);
  }
}

.submit-button {
  color: #3081ee;
  border: none;
  border-radius: 24px;
  cursor: pointer;
  outline: none;
  margin-left: 12px;
  display: grid;
  background: none;
  place-content: center;
}

.submit-button .submit-icon {
  display: block; /* Hide the icon on larger screens */
  height: 24px;
  width: 24px;
}
.submit-button .text {
  display: none;
  color: #fff;
  font-family: "DM-Sans", sans-serif;
  font-size: 0.8rem;
}

.submit-button.night-mode-icon {
  color: white;
}

@media (min-width: 768px) {
  .submit-button {
    padding: 16px 40px;
    background: linear-gradient(15deg, #2f80ed 26.3%, #56ccf2 86.4%);
    width: fit-content;
    height: fit-content;
    margin-left: 2vh;
  }

  .submit-button .submit-icon {
    display: none;
  }
  .submit-button .text {
    display: block;
  }
}
/* Speaker Icon and Chatbot Voice Options */
.chatbot-voice-control-container {
  position: absolute;
  display: grid;
  place-content: center;
  right: 16px;
  top: 24px;
  padding: 0px 0px;
  border-radius: 12px;
  background-color: #fff;
  min-width: fit-content;
  box-shadow: 0px 4px 4px 0px rgba(113, 128, 150, 0.17);
}

.chatbot-voice-control-container.night-mode {
  background-color: #1c3252;
}

.chatbot-voice-control-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4px 8px;
}

.voice-control-icons-container {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
  gap: 8px;
  width: 100%;
}

.chatbot-speaker-icon.on {
  width: 32px;
  height: 32px;
  color: rgba(57, 146, 239, 1);
  cursor: pointer;
}

.chatbot-speaker-icon.on.night-mode-icon {
  color: white;
}

.chatbot-speaker-icon.chevron {
  width: 16px;
  height: 16px;
  color: rgba(57, 146, 239, 1);
  cursor: pointer;
  transform: rotate(0deg);
  transition: all 0.2 ease;
}

.chatbot-speaker-icon.chevron.night-mode-icon {
  color: white;
}

.chatbot-speaker-icon.chevron.rotate {
  transform: rotate(180deg);
}

.chatbot-speaker-icon.off {
  width: 32px;
  height: 32px;
  color: rgba(113, 128, 150, 1);
  cursor: pointer;
}

.chatbot-speaker-icon.off.night-mode-icon {
  color: white;
}

.chatbot-voice-options-container {
  height: 0px;
  transition: height 0.2s ease;
  overflow: hidden;
}

.chatbot-voice-options-container.open {
  display: flex;
  flex-direction: column;
  gap: 2px;
  height: 160px;
}

.chatbot-voice-option {
  cursor: pointer;
  padding: 4px;
  border-radius: 4px;
  transition: background-color 0.1s ease-in-out;
  padding-inline: 4px;
}

.chatbot-voice-option > p {
  padding: 0;
  margin: 0;
  font-family: "DM-Sans", sans-serif;
  font-weight: 400;
  font-size: clamp(0.8rem, 1vw, 0.9rem);
  color: rgba(48, 129, 238, 1);
}

.chatbot-voice-control-container.night-mode .chatbot-voice-option > p {
  color: white;
}

.chatbot-voice-option:hover {
  background-color: rgba(54, 124, 236, 0.1);
}

.chatbot-voice-option.selected {
  background-color: rgba(235, 243, 254, 1);
}

.chatbot-voice-control-container.night-mode .chatbot-voice-option.selected,
.chatbot-voice-control-container.night-mode .chatbot-voice-option:hover {
  background-color: rgb(66, 94, 137);
}

/* chat container layout */

.chat-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  overflow-y: auto;
  flex: 1;
}

/* Hide scrollbar for Chrome, Safari, and Opera */
.chat-container::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge, and Firefox */
.chat-container {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.chat-container .user,
.chat-container .bot {
  display: flex;
  align-items: center;
  margin: 10px;
  font-family: Arial, Helvetica, sans-serif;
  white-space: pre-line;
  line-height: 20px;
}

.input-container-button {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.input-container-button2 {
  position: relative;
  display: flex;
  flex-shrink: 1;
  align-items: center;
  width: 100%;
  max-height: 200px;
  overflow-y: auto;
}

.input-container-button2 .scrollable-input {
  width: 100%;
  box-sizing: border-box;
  resize: none;
  overflow-y: auto;
  white-space: pre-wrap;
}

.regenerate-button {
  margin-block: 16px;
  padding: 12px 18px;
  font-family: "DM Sans", sans-serif;
  background-color: transparent;
  border: 1px solid #3081ee;
  border-radius: 50px;
  cursor: pointer;
  color: #3081ee;
  display: flex;
  align-items: center;
}

.regenerate-icon {
  color: #3081ee;
  margin-right: 8px;
}

.regenerate-button.night-mode-regenerate {
  border-color: white;
  background-color: #555050;
  color: white;
}

.regenerate-button.night-mode-regenerate .regenerate-icon {
  color: white;
}

.chat-container .user .image {
  margin-right: 24px;
  border-radius: 24px;
  border: 1px solid rgba(226, 232, 240, 1);
  color: rgba(57, 146, 239, 1);
  padding: 12px;
  height: 16px;
  width: auto;
}

.chat-container .bot .image {
  margin-right: 25px;
  background-color: rgba(196, 222, 250, 0.6);
  border-radius: 100%;
  padding: 8px 10px;
  height: 24px;
  width: auto;
}

.chat-container .user .image.night-mode {
  background-color: transparent;
  border: 1px solid rgb(234, 238, 244);
  color: rgb(234, 238, 244);
}

.chat-container .bot .image.night-mode {
  background-color: #1c3252;
  border: 1px solid #243f65;
}

.chat-container .user-message p,
.chat-container .bot-message p {
  background-color: #fff;
  padding: 10px 15px;
  border-radius: 10px;
  word-wrap: break-word;
  text-align: left;
  color: #3b3b3c;
  font-family: "DM Sans", sans-serif;
}

.chat-container .user .user-message,
.chat-container .bot .bot-message {
  background-color: initial;
  padding: initial;
  border-radius: initial;
  word-wrap: initial;
  text-align: initial;
  border-radius: 9px;
  font-family: "DM Sans", sans-serif;
}

.chat-container .user .user-message p,
.chat-container .bot .bot-message p {
  margin: 0;
  font-family: "DM Sans", sans-serif;
}

.dashboard-chatbot-inner {
  display: flex;
  justify-content: center;
  width: 80%;
  max-width: 800px;
  min-height: 80%;
  max-height: 90%;
  flex: 1;
  margin-top: 64px;
  align-items: flex-end;
  z-index: 300;
}

/* the profile buttons */

.dashboard-container.blur {
  filter: blur(5px);
}

.popup-container-payment {
  position: absolute;
  height: 100%;
  width: 60%;
  margin-left: 20%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.popup-content-wide {
  overflow-y: auto;
  width: clamp(300px, 70vw, 800px);
  max-height: 80vh;
  flex-grow: 1;
  background-color: #fff;
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: center;
  padding: 24px;
  box-shadow: 5px 5px 20px rgba(88, 87, 87, 0.3);
  border-radius: 10px;
  z-index: 6000;
  position: relative;
}

.popup-content-wide .welcome-message {
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 8px;
  margin: 0;
  margin-bottom: -32px;
}

.welcome-message * {
  margin: 0;
  padding: 0;
  font-family: "DM-Sans", sans-serif;
}

.popup-text-basic {
  text-align: center;
  color: #1b2559;
  font-family: "DM-Sans", sans-serif;
}

.subscription-confirmation-popup-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.popup-content.popup-night-mode .popup-text-basic {
  color: white;
}

.logout-confirmation {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  justify-content: space-evenly;
  gap: 32px;
}

.logout-confirmation > p {
  font-family: "Arial, Helvetica," sans-serif;
  color: #1b2559;
  margin: 0;
}

.logout-confirmation.night-mode-text > p {
  color: #fff;
}

.popup-button-logout {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 80%;
  justify-content: center;
  gap: clamp(5%, 10vw, 20%);
}

.popup-button-logout button {
  padding: 16px 32px;
  background-color: rgb(2, 96, 196);
  color: #ffffff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.popup-button-logout1:hover {
  background-color: rgba(0, 123, 255, 0.8);
}

.popup-button-logout1:last-child {
  background-color: rgba(220, 53, 69, 0.9);
}

.popup-button-logout1:last-child:hover {
  background-color: rgba(230, 81, 96, 0.9);
}

/* setting popup css */

.popup-settings {
  display: flex;
  flex-direction: column;
}

.popup-night-mode {
  background: #243f65;
}

.popup-settings-header.night-mode-text h3 {
  color: white;
}

.popup-settings-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px;
  border-bottom: 1px solid #ccc;
  padding-block: clamp(10px, 10vh, 20px);
}

.popup-settings-section:last-child {
  border: none;
}

.popup-settings-section p {
  margin: 0;
  font-weight: bold;
}

.popup-settings-option.night-mode-text {
  color: #fff;
}

.popup-button-setting {
  background-color: #e74c3c;
  color: #fff;
  padding: 7px 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  min-width: 72px;
  max-width: 15%;
}

.theme-dropdown select {
  background-color: transparent;
  color: rgb(165, 163, 163);
}

.popup-button-setting1 {
  background-color: transparent;
  color: #3992ef;
  padding: 6px 10px;
  border: 1px solid rgb(179, 178, 178);
  border-radius: 5px;
  cursor: pointer;
  min-width: 72px;
  max-width: 15%;
}
.popup-button-setting1:disabled {
  background-color: #ccc;
  color: #999;
  cursor: not-allowed;
}

.popup-button-setting1:hover:disabled::before {
  content: "Take a subscription";
  position: absolute;
  background-color: #f00;
  color: #fff;
  padding: 5px;
  border-radius: 5px;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 12px;
  opacity: 0.9;
  z-index: 1;
}

.popup-button-setting1.night-mode-text {
  color: #fff;
}

.popup-settings-section p,
.popup-settings-section-deleteall {
  color: #718096;
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 200;
}

.popup-settings-header {
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-settings-close {
  cursor: pointer;
  font-size: 30px;
  color: #888;
  position: absolute;
  right: 5%;
  top: 5%;
}

.popup-settings-back {
  cursor: pointer;
  font-size: 30px;
  color: #888;
  position: absolute;
  left: 5%;
  top: 5%;
}

.popup-settings-close.night-mode-icon,
.popup-settings-back.night-mode-icon {
  color: #fff;
}

/* Footer */

footer {
  height: fit-content;
  width: 100%;
  padding-block: 8px;
}

.footer-content {
  font-size: clamp(0.4rem, 2.5vw, 0.6rem);
  font-family: "DM Sans", Helvetica, sans-serif;
  color: grey;
  font-size: clamp(0.5rem, 1vw, 0.6rem);
  display: flex;
  flex-direction: row;
  flex-wrap: wrap-reverse;
  justify-content: space-between;
  text-align: center;
  text-justify: center;
  width: 80%; /* Same as the chatbot (.dashboard-chatbot-inner) */
  max-width: 800px; /* Same as the chatbot (.dashboard-chatbot-inner) */
}

.footer-content.night-mode {
  color: rgb(226, 226, 226);
}

.footer-content > * {
  margin: 0;
  padding: 0;
}

/* add username */

.second-popup {
  position: fixed;
  top: 20%;
  left: 32%;
  width: 35%;
  height: 50vh;
  background-color: #fff;
  padding: 21px 30px;
  border: 1px solid #ccc;
  box-shadow: 5px 5px 20px rgba(88, 87, 87, 0.3);
  border-radius: 10px;
  z-index: 2000;
}

.popup-settings-header {
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-settings-header h3 {
  font-family: Arial, sans-serif;
  color: rgb(7, 32, 95);
}

.popup-settings-section.username,
.popup-settings-section.password {
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.popup-settings-section.username .input-container,
.popup-settings-section.password .input-container {
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 100%;
}

.popup-settings-section.username .input-container label,
.popup-settings-section.password .input-container label {
  display: flex;
  flex-direction: column;
  gap: 12px;
  color: rgba(113, 128, 150, 1);
  font-family: Arial, Helvetica, sans-serif;
}

.popup-settings-section.username .input-container input,
.popup-settings-section.password .input-container input {
  width: clamp(90%, 50vw, 94%);
  border: 1px solid #e0e0e0;
  border-radius: 16px;
}

.popup-settings-section.username button,
.popup-settings-section.password button {
  width: 100%;
  padding: 16px;
  margin-top: 8px;
  border: 1px solid rgb(13, 128, 229);
  border-radius: 12px;
  background-color: white;
  color: rgba(48, 129, 238, 1);
  transition: background-color 0.15s ease;
  cursor: pointer;
}

.popup-settings-section.username button:hover,
.popup-settings-section.password button:hover {
  background-color: rgb(13, 128, 229);
  color: white;
}

.popup-settings-section.night-mode-text.username button,
.popup-settings-section.night-mode-text.password button {
  background-color: transparent;
  color: white;
  border: 1px solid #c1dffd;
}

.popup-settings-section.night-mode-text.username button:hover,
.popup-settings-section.night-mode-text.password button:hover {
  background-color: rgba(40, 71, 113, 0.681);
}

.popup-settings-section.night-mode-text.username label,
.popup-settings-section.night-mode-text.password label {
  color: rgba(193, 223, 253, 1);
}

.popup-settings-section.night-mode-text.username input,
.popup-settings-section.night-mode-text.password input {
  background-color: transparent;
  color: white;
}

/* 75 celebration popup */

.orange-star-popup {
  position: fixed;
  top: 20%;
  left: 32%;
  width: 30%;
  height: 52vh;
  background-color: #fff;
  padding: 21px 7vh;
  border: 1px solid #ccc;
  box-shadow: 5px 5px 20px rgba(88, 87, 87, 0.3);
  border-radius: 10px;
  z-index: 2000;
}

.Day75StreakImage {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25vh;
  height: 23vh;
  margin: 0 auto;
  margin-top: 25px;
}

.orange-star-popup p {
  font-family: Arial, Helvetica, sans-serif;
  color: #4b4949;
  line-height: 1.5;
  align-self: center;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Day75ButtonLeft {
  background-color: rgb(54, 124, 236);
  color: white;
  padding: 15px 15px;
  border-radius: 8px;
  border: none;
  margin-right: 20px;
}

.Day75ButtonRight {
  background-color: white;
  color: rgb(54, 124, 236);
  padding: 15px 15px;
  border-radius: 8px;
  border: 1px solid rgb(54, 124, 236);
}

.error-popup-register-user {
  position: fixed;
  top: 30%;
  left: 32%;
  width: 30%;
  height: 15vh;
  background-color: #fff;
  padding: 21px 7vh;
  border: 1px solid #ccc;
  box-shadow: 5px 5px 20px rgba(88, 87, 87, 0.3);
  border-radius: 10px;
  z-index: 2000;
}
.error-popup-register-user {
  font-family: Arial, Helvetica, sans-serif;
}

.error-popup-register-user button.close-button-day75 {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 40px;
  color: #888;
  background-color: white;
  border: none;
}

.orange-star-popup div {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 10px;
}

.orange-star-popup button.close-button-day75 {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 40px;
  color: #888;
  background-color: white;
  border: none;
}

.box-with-semicircle {
  width: 70%;
  height: 25%;
  position: relative;
  background-color: #3498db;
  overflow: hidden;
  margin: 20px;
  margin-top: 100%;
  transform: scaleY(-1);
}

.box-with-semicircle:before {
  content: "";
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 70px;
  height: 70px;
  background-color: #0b0c0d;
  border-radius: 50%;
  margin-top: 100%;
  background-size: cover;
}

/* dropdown arrow */

.dropdown {
  position: relative;
  display: inline-block;
  cursor: pointer;
  z-index: 4000;
  border-radius: 10px;
}

.dropdown-content {
  position: absolute;
  top: 80px;
  display: grid;
  place-content: center;
  background-color: #f9f9f9;
  width: clamp(220px, 45vw, 280px);
  padding: 8px;
  margin-left: 16px;
  border-radius: 10px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 4000;
}

.dropdown-content.night-mode-dropdown {
  background-color: #1c3252;
  border: 1px solid #88bef5;
}

.dropdown-content.night-mode-dropdown .field-content .text {
  color: white;
}

.dropdown-content.night-mode-dropdown .field-content .subtext {
  color: rgba(136, 190, 245, 1);
}

.dropdown-content.night-mode-dropdown .field-content .input {
  background-color: rgba(136, 190, 245, 1);
}

.dropdown-field {
  display: flex;
  flex-direction: column;
  gap: 20px;
  margin-block: 12px;
}

.checkbox-label {
  display: flex;
  align-items: center;
}

.dropdown-arrow {
  font-size: 1.5rem;
  margin-left: 10px;
  transform: rotate(0deg);
  transition: all 0.1s ease-in-out;
}

.dropdown-arrow.dropdown-open {
  transform: rotate(-180deg);
}

.field-content {
  display: flex;
  align-items: center;
  gap: 24px;
  width: 100%;
}

.text-subtext {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 4px;
  margin-left: 10px;
  white-space: nowrap;
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
}
.text {
  font-size: 1.7vh;
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
}
.subtext {
  font-size: 1.5vh;
  margin: 0;
  color: grey;
  font-family: Arial, Helvetica, sans-serif;
}

.upgradePremium {
  width: 100%;
  background-color: rgb(15, 114, 244);
  border-radius: 10px;
  padding: 10px 15px;
  border: none;
  color: white;
  font-family: Arial, Helvetica, sans-serif;
}

/* stripe popup */
#stripeTableContainer {
  margin: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.stripe-popup {
  position: fixed;
  top: 20%;
  left: 32%;
  width: 100%;
  height: 44vh;
  background-color: #fff;
  padding: 21px 7vh;
  border: 1px solid #ccc;
  box-shadow: 5px 5px 20px rgba(88, 87, 87, 0.3);
  border-radius: 10px;
  z-index: 2000;
}

.stripe-popup button.close-button-day75 {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  font-size: 40px;
  color: #888;
  background-color: white;
  border: none;
}

/* add chat button */

.addchat-button {
  display: flex;
  background-color: transparent;
  color: #3081ee;
  border: 1px solid #3081ee;
  border-radius: 10px;
  padding-block: 16px;
  margin-block: 16px;
  cursor: pointer;
  width: 100%;
  align-self: center;
  justify-content: center;
  font-family: Arial, Helvetica, sans-serif;
  transition: all 0.1s ease;
}

.addchat-button.night-mode-button {
  border-color: #fff;
  color: #fff;
}

.addchat-button:hover {
  background-color: #ebf3fe;
}

.addchat-button:active {
  background-color: rgba(0, 123, 255, 0.2);
}

.addchat-button.night-mode-button:hover {
  background-color: rgb(66, 94, 137);
}

.addchat-button.night-mode-button:active {
  background-color: rgb(42, 63, 96);
}

/* ChatGoalCards.js */
.popup-content-wide .card-container {
  display: flex;
  flex-direction: column;
  width: 90%;
  justify-self: center;
  margin-bottom: 32px;
  gap: 32px;
}

.card-header {
  text-align: center;
  margin-top: 32px;
}

.card-header h2 {
  font-family: Arial, Helvetica, sans-serif;
  color: #1b2559;
}

.card-header.night-mode-text h2 {
  color: white;
}

.card-header p {
  font-family: Arial, Helvetica, sans-serif;
  color: #68769f;
  font-size: clamp(0.8rem, 1.5vw, 1rem);
}

.card-header.night-mode-text p {
  color: #c1dffd;
}

.card-container .card1,
.card-container .card2 {
  min-height: 250px;
  position: relative;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  cursor: pointer;
  justify-content: center;
  padding: 12px;
  border-radius: 16px;
  background: #fff;
  box-shadow: 12px 24px 40px 4px rgba(112, 144, 176, 0.2);
}

.card-container .card2.selected {
  background: rgb(234, 242, 253, 1);
}

.card-container .card2.night-mode-card.selected {
  background: rgb(37, 73, 127);
}

.card-container .card1.night-mode-card,
.card-container .card2.night-mode-card {
  background-color: #1c3252;
  box-shadow: 12px 24px 40px 4px rgba(112, 144, 176, 0.2);
}

.card-container .card1-info h3,
.card-container .card2 h3 {
  font-family: Arial, Helvetica, sans-serif;
  color: #68769f;
  font-size: clamp(1rem, 1.5vw, 1.2rem);
}

.card-container .card1.night-mode-card .card1-info h3,
.card-container .card2.night-mode-card h3 {
  color: white;
}

.card-container .card1-info p,
.card-container .card2 .speech-bubble p {
  font-family: Arial, Helvetica, sans-serif;
  color: #68769f;
  font-size: clamp(0.8rem, 1.5vw, 1rem);
}

.card-container .card1.night-mode-card .card1-info p,
.card-container .card2.night-mode-card .speech-bubble p {
  color: #c1dffd;
}

.card-container .card1-img-container {
  position: relative;
}

.card-container .card1 div > img {
  height: 128px;
  width: auto;
  align-self: center;
  z-index: 5;
}

.card1-img-container svg {
  position: absolute;
  translate: -16px -16px;
  visibility: hidden;
  z-index: 1;
}

.card1:hover .card1-img-container svg {
  position: absolute;
  visibility: visible;
  z-index: 1;
}

.card1.disabled-card,
.card2.disabled-card {
  cursor: not-allowed;
}

.card-container .card2:hover {
  background: rgb(234, 242, 253, 1);
  transition: all 0.1s ease;
}

.card-container .card2.night-mode-card:hover {
  background: rgb(37, 73, 127);
  transition: all 0.1s ease;
}

.card-container .card2.night-mode-card:active {
  background-color: rgb(24, 64, 124);
}

.speech-bubble {
  position: relative;
}

.speech-bubble p {
  position: absolute;
  max-width: 150px; /* To fit inside speech bubble svg (180px) */
  translate: 16px 40px; /* Centers p in speech bubble */
  line-height: 20px;
}

.create-chat-button {
  display: flex;
  color: white;
  border: 1px solid rgb(0, 123, 255);
  border-radius: 10px;
  padding-block: 16px;
  margin-block: 16px;
  cursor: pointer;
  width: clamp(90%, 10vw, 500px);
  align-self: center;
  justify-content: center;
  font-family: Arial, Helvetica, sans-serif;
  transition: all 0.1s ease;
}

.create-chat-button.disabled {
  cursor: not-allowed;
  background-color: transparent;
  color: rgb(190, 190, 190);
  border: 1px solid rgb(190, 190, 190);
}

.create-chat-button.enabled,
.popup-content-wide.popup-night-mode .create-chat-button.enabled {
  color: white;
  background-color: rgba(0, 123, 255, 1);
  border: 1px solid rgb(0, 123, 255);
}

.create-chat-button.enabled:hover,
.popup-content-wide.popup-night-mode .create-chat-button.enabled:hover {
  background-color: rgba(0, 123, 255, 0.9);
}

.create-chat-button.enabled:active,
.popup-content-wide.popup-night-mode .create-chat-button.enabled:active {
  background-color: rgba(0, 123, 255, 0.98);
}

.upgrade-button {
  z-index: 1000;
  background-color: #1863e5;
  opacity: 1;
  color: white;
  padding: 8px 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s ease;
}

.upgrade-button:hover {
  background-color: #1c6fff;
}

.disabled-card .upgrade-button {
  pointer-events: auto;
}

.card1 .disabled-card-overlay,
.card2 .disabled-card-overlay {
  background: rgba(255, 252, 252, 0.2);
  backdrop-filter: blur(0.8px);
  position: absolute;
  display: grid;
  place-content: center;
  width: 100%;
  height: 100%;
  border-radius: 16px;
  z-index: 999;
}

.card1.night-mode-card .disabled-card-overlay,
.card2.night-mode-card .disabled-card-overlay {
  background: rgba(124, 124, 124, 0.2);
}

@media (min-width: 1024px) {
  .popup-content-wide .card-container {
    flex-direction: row;
    width: 100%;
    justify-content: space-evenly;
    gap: 32px;
  }

  .card-container .card1,
  .card-container .card2 {
    flex-basis: 25%;
  }
}

/* End ChatGoalCards.js */

.chat-list {
  flex-grow: 2;
  flex-shrink: 3;
  display: flex;
  flex-direction: column;
  margin-bottom: 32px;
  width: 100%;
}

.chat-list p {
  font-family: Arial, Helvetica, sans-serif;
  font-size: smaller;
}

/* display of chat sessions */
.sessions-header {
  color: #718096;
  font-size: 16px;
  padding-left: 8px;
}

.sessions-header.night-mode-session-header {
  color: #b5b5b5;
}

.chat-sessions-container {
  overflow-y: auto;
  overflow-x: hidden;
  display: flex;
  gap: 2px;
  flex-direction: column;
  height: 100%;
}

.session-container {
  display: flex;
  position: relative;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.1s ease-in;
}

.chat-sessions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  padding-inline: 8px;
}

.chat-session-info {
  padding-inline: 8px;
}

.chat-session-info-text {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 20px;
  color: rgba(27, 37, 89, 1);
  flex-grow: 1;
  width: 200px;
}

.chat-session-info-text.night-mode-text {
  color: white;
}

.session-container button {
  border: none;
  cursor: pointer;
  background: none;
  visibility: hidden;
  border: none;
  box-sizing: border-box;
  color: black;
  height: 100%;
  width: 20%;
  border-radius: 0 8px 8px 0;
  position: absolute;
  right: 0;
  z-index: 100;
}

.session-container.night-mode-session button {
  background: none;
}

.session-container:hover button {
  visibility: visible;
  z-index: 5;
}

.session-container:hover {
  background-color: rgba(235, 243, 254, 1);
}

.session-container.night-mode-session:hover {
  background-color: rgb(66, 94, 137);
}

.session-container button:hover {
  color: #3081ee;
}

.session-container.night-mode-session button:hover .night-mode-icon {
  color: #3081ee;
}

.session-container.selected-session,
.session-container.selected-session:hover {
  background-color: rgba(235, 243, 254, 1);
}

.session-container.night-mode-session.selected-session,
.session-container.night-mode-session.selected-session:hover {
  background-color: rgb(66, 94, 137);
}

/* Customize the scrollbar */
/* Track */
::-webkit-scrollbar {
  width: 8px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background-color: #aaaaaa;
  border-radius: 6px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: #707070;
}

/* Track */
::-webkit-scrollbar-track {
  background-color: rgba(255, 255, 255, 0.05);
  border-radius: 4px;
}

/* Handle on active (click) */
::-webkit-scrollbar-thumb:active {
  background-color: #6c6c6c;
}

/* Circle Loading Animation */
.lds-roller {
  color: #3081ee;
}
.lds-roller,
.lds-roller div,
.lds-roller div:after {
  box-sizing: border-box;
}
.lds-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}
.lds-roller div {
  animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  transform-origin: 40px 40px;
}
.lds-roller div:after {
  content: " ";
  display: block;
  position: absolute;
  width: 7.2px;
  height: 7.2px;
  border-radius: 50%;
  background: currentColor;
  margin: -3.6px 0 0 -3.6px;
}
.lds-roller div:nth-child(1) {
  animation-delay: -0.036s;
}
.lds-roller div:nth-child(1):after {
  top: 62.62742px;
  left: 62.62742px;
}
.lds-roller div:nth-child(2) {
  animation-delay: -0.072s;
}
.lds-roller div:nth-child(2):after {
  top: 67.71281px;
  left: 56px;
}
.lds-roller div:nth-child(3) {
  animation-delay: -0.108s;
}
.lds-roller div:nth-child(3):after {
  top: 70.90963px;
  left: 48.28221px;
}
.lds-roller div:nth-child(4) {
  animation-delay: -0.144s;
}
.lds-roller div:nth-child(4):after {
  top: 72px;
  left: 40px;
}
.lds-roller div:nth-child(5) {
  animation-delay: -0.18s;
}
.lds-roller div:nth-child(5):after {
  top: 70.90963px;
  left: 31.71779px;
}
.lds-roller div:nth-child(6) {
  animation-delay: -0.216s;
}
.lds-roller div:nth-child(6):after {
  top: 67.71281px;
  left: 24px;
}
.lds-roller div:nth-child(7) {
  animation-delay: -0.252s;
}
.lds-roller div:nth-child(7):after {
  top: 62.62742px;
  left: 17.37258px;
}
.lds-roller div:nth-child(8) {
  animation-delay: -0.288s;
}
.lds-roller div:nth-child(8):after {
  top: 56px;
  left: 12.28719px;
}
@keyframes lds-roller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* SettingsMenu.js */
.settings-container {
  box-sizing: border-box;
  width: clamp(320px, 90vw, 850px);
  font-family: "DM Sans", "Inter", Helvetica, sans-serif;
  background: none;
}

.settings-header {
  color: rgba(27, 37, 89, 1);
  height: 72px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  border-bottom: 1px solid #e1e1e1;
}

.settings-header h3 {
  font-size: 20px;
  margin-left: 24px;
}

.settings-content-container {
  padding: 24px;
  width: 100%;
  height: fit-content;
  display: flex;
  flex-direction: column;
  overflow: visible;
  box-sizing: border-box;
}

.settings-submenu {
  display: flex;
  flex-direction: column;
  width: 90%;

  max-width: 250px;
  margin-block: 24px;
  gap: 8px;
}

.settings-submenu-item {
  display: flex;
  gap: 12px;
  cursor: pointer;
  align-items: center;
  border-radius: 4px;
  text-align: center;
  min-width: 180px;
  max-width: 75%;
  min-height: 32px;
  max-height: 32px;
  padding-block: 4px;
  padding-left: 16px;
  flex: 1;
  color: rgba(27, 37, 89, 1);
}

.settings-submenu-item.selected {
  background-color: #ebf3fe;
  color: rgba(48, 129, 238, 1);
}

.settings-submenu-actions {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.settings-submenu-action {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-block: 16px;
  gap: 16px;
  color: #1b2559;
}

.settings-submenu-action.secondary {
  justify-content: flex-start;
  gap: 16px;
  padding-left: 0px;
  border: none !important;
}

.settings-submenu-action.google {
  flex-wrap: wrap;
}

.google-submenu-action-inner {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  word-break: break-all;
}

.secondary-submenu-action-content {
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  gap: 20px;
  text-align: center;
}

.secondary-submenu-action-content > p {
  padding: 0;
  padding-inline: 16px;
  margin-block: 0;
  color: #1b2559;
  font-family: "DM Sans", "Inter", sans-serif;
}

.settings-submenu-action:not(:last-of-type) {
  border-bottom: 1px solid rgba(225, 225, 225, 1);
}

.confirmation-buttons {
  margin-block: 8px;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  flex-wrap: wrap-reverse;
  gap: 16px;
}

.confirmation-buttons button {
  max-width: 250px;
  margin-inline: 0;
  font-size: 15px;
  padding-inline: 16px;
}

.settings-dropdown {
  position: relative;
}

.settings-dropdown-preview {
  display: flex;
  gap: 8px;
  align-items: center;
  cursor: pointer;
}

.settings-dropdown-options {
  max-height: 0px;
  overflow: hidden;
  position: absolute;
  top: 40px;
  right: 0;
  white-space: nowrap;
  transition: max-height 0.1s ease-in-out;
}

.settings-dropdown-options.visible.theme {
  max-height: 120px;
  border-radius: 15px;
  border: 1px solid #d4d4d4;
  background: #fff;
  width: fit-content;
}

.settings-dropdown-options.visible.voice {
  max-height: 120px;
  overflow-y: auto;
  border-radius: 15px;
  border: 1px solid #d4d4d4;
  background: #fff;
  width: fit-content;
}

@media (min-width: 768px) {
  .settings-dropdown-options.visible.theme {
    max-height: 120px;
  }

  .settings-dropdown-options.visible.voice {
    max-height: 250px;
  }
}

.settings-dropdown-options > * {
  margin: 0;
  padding: 0;
  padding-inline: 16px;
  padding-block: 8px;
}

.settings-dropdown-options > .selected {
  background-color: #ebf3fe;
  border-radius: 4px;
  color: rgba(48, 129, 238, 1);
}

.settings-dropdown-options.hidden > * {
  display: none;
}

.settings-dropdown-options > *:hover {
  cursor: pointer;
  background-color: #ebf3fe;
  border-radius: 4px;
  color: rgba(48, 129, 238, 1);
}

@media (min-width: 768px) {
  .settings-content-container {
    flex-direction: row;
  }

  .settings-submenu {
    flex-direction: column;
  }
}

.settings-action-button {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  min-width: 125px;
  max-width: 195px;
  margin-block: auto;
  height: 40px;
  border-radius: 8px;
  cursor: pointer;
  gap: 8px;
  font-family: "DM Sans", "Inter", sans-serif;
  font-size: 1rem;
  color: #3081ee;
  border: 1px solid #3081ee;
  background-color: transparent;
  transition: background-color 0.15s ease-in-out;
  margin-left: auto;
  white-space: nowrap;
}

.settings-action-button:hover {
  background: rgba(235, 243, 254, 1);
}

.settings-action-button:active {
  background-color: rgba(0, 123, 255, 0.2);
}

.settings-action-button[disabled] {
  cursor: not-allowed;
  border: 1px solid #b5b5b5;
  color: #b5b5b5;
}

.settings-action-button.danger {
  margin-block: auto;
  border: 1px solid #e94040;
  background: #e94040;
  color: white;
}

.settings-action-button.danger:hover {
  border: 1px solid #eb5252;
  background: #eb5252;
}

.settings-action-button.danger:active {
  border: 1px solid #e03838;
  background: #e03838;
}

.settings-action-icon-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 16px;
}

.submit-message {
  margin: 0;
  padding: 0;
  max-width: 80%;
  font-size: 0.8rem;
  color: #3081ee;
  margin-inline: auto;
}

.submit-message.error {
  color: #e94040 !important;
}

.settings-action-icon {
  font-size: 32px;
  color: #3081ee;
  cursor: pointer;
}

.settings-back-arrow {
  cursor: pointer;
  transition: transform 0.2s ease-in-out;
}

.settings-back-arrow:hover {
  transform: translateX(-6px);
}

.input-container {
  position: relative;
  width: 100%;
  box-sizing: content-box;
}

.input-container input {
  background: #fff;
  border: 1px solid #c2c8d0;
  border-radius: 6px;
  box-sizing: border-box;
  color: rgb(99, 99, 99);
  font-family: "Inter", sans-serif;
  font-size: 1rem;
  height: 52px;
  line-height: 20px;
  padding: 0 16px;
  width: 100%;
  transition: box-shadow 0.2s ease-in-out, border-color 0.2s ease-in-out;
  text-align: start;
}

.input-container input:focus,
.input-container input:not(:placeholder-shown) {
  border: 2px solid #3081ee;
}

.input-label {
  position: absolute;
  background-color: #fff;
  color: rgb(99, 99, 99);
  font-size: 1rem;
  font-weight: 400;
  top: 16px;
  left: 12px;
  max-width: 90%;
  overflow: hidden;
  pointer-events: none;
  padding: 1px 6px;
  text-overflow: ellipsis;
  transform-origin: 0;
  transition: transform 0.15s ease-in-out, top 0.15s ease-in-out, color 0.15s ease-in-out, font-size 0.15s ease-in-out;
  white-space: nowrap;
  z-index: 1;
}

.input-container input:not(:placeholder-shown) + .input-label,
.input-container input:focus + .input-label {
  top: -8px;
  color: #3081ee;
  font-size: 0.9rem;
}

.eye-toggle-icon {
  cursor: pointer;
  position: absolute;
  color: #1b2559;
  top: 36%;
  right: 20px;
  width: 16px;
  height: 16px;
}

/* Settings night mode */
.settings-container.night-mode .settings-header,
.settings-container.night-mode .settings-submenu-action p,
.settings-container.night-mode .settings-icon.dropdown,
.settings-container.night-mode .settings-submenu-item,
.settings-container.night-mode .settings-submenu-action.secondary,
.settings-container.night-mode .eye-toggle-icon,
.settings-container.night-mode .input-label,
.settings-container.night-mode input,
.settings-container.night-mode .secondary-submenu-action-content > p {
  color: white;
}

.settings-container.night-mode .settings-dropdown-options.visible {
  background: #1c3252;
  border: 1px solid #122035;
}

.settings-container.night-mode .settings-dropdown-options > .selected,
.settings-container.night-mode .settings-dropdown-options > p:hover,
.settings-container.night-mode .settings-submenu-item.selected,
.settings-container.night-mode .settings-submenu-item:hover,
.settings-container.night-mode .settings-action-button:hover {
  background-color: rgb(66, 94, 137);
}

.settings-container.night-mode input,
.settings-container.night-mode .input-label {
  background: #1c3252;
}

.settings-container.night-mode input:focus {
  outline: none;
}

.settings-container.night-mode .settings-action-button.danger:hover {
  border: 1px solid #eb5252;
  background: #eb5252;
}

.settings-container.night-mode .settings-action-button.danger:active {
  border: 1px solid #e03838;
  background: #e03838;
}

/* Popup.js */
.popup-container {
  overflow: visible;
  position: absolute;
  height: 100%;
  width: 100%;
  display: grid;
  place-content: center;
  z-index: 9000;
  background-color: rgba(17, 17, 17, 0.5);
  font-family: "Inter", "DM Sans", Arial, sans-serif;
}

.popup-content {
  /* overflow-y: auto; */
  width: fit-content;
  height: fit-content;
  overflow: visible;
  flex-grow: 1;
  background-color: #fff;
  box-shadow: 5px 5px 20px rgba(88, 87, 87, 0.3);
  border-radius: 10px;
  z-index: 6000;
  position: relative;
  transition: background-color 0.15s ease, color 0.15s ease;
}

.popup-content.popup-night-mode {
  background-color: #1c3252;
}

.popup-close-icon-container {
  width: 24px;
  height: 24px;
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
}

.popup-close-icon {
  width: 24px;
  height: 24px;
  color: rgba(27, 37, 89, 1);
}

.popup-content.popup-night-mode .popup-close-icon {
  color: white;
}

.popup-overlay {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  z-index: 3000;
}

.small-popup-container {
  width: fit-content;
  padding: 52px 32px 32px 32px;
}
