/* Login.js / Signout.js */
.authorization-page-container {
  width: 100%;
  min-height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow-y: auto;
}

.auth-page-company-logo {
  height: 128px;
  display: flex;
  align-items: flex-end;
}

.auth-page-company-logo * {
  margin: 0;
}

.company-button {
  display: flex;
  align-items: left;
  justify-items: left;
  padding: 0;
}

.logo-name {
  display: flex;
  align-items: center;
  min-width: fit-content;
  flex-basis: 25%;
  gap: 16px;
}

.logo-name > img {
  width: auto;
  height: 2.5rem;
}

.logo-name > h3 {
  text-shadow: 2px 2px 4px rgba(0, 0, 0.3, 0.3);
  text-decoration: none;
  width: fit-content;
  margin: 0;
  color: #1b2559;
  font: weight 2000px;
  font-family: "Inter", sans-serif;
}

.authorization-page-content {
  width: clamp(280px, 95vw, 320px);
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: "Inter", "DM Sans", sans-serif;
}

.auth-page-form-content {
  width: 100%;
  height: 100%;
  margin-top: 96px;
  margin-bottom: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 16px;
  flex-grow: 1;
}

.authorization-page-content h1 {
  font-family: "Inter", sans-serif;
  font-size: 32px;
  margin: 12px;
}

.authorization-page-content form {
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 16px;
}

.input-container {
  position: relative;
  width: 100%;
  box-sizing: content-box;
}

.input-container input {
  background: #fff;
  border: 1px solid #c2c8d0;
  border-radius: 6px;
  box-sizing: border-box;
  color: rgb(99, 99, 99);
  font-family: "Inter", sans-serif; /*inherit*/
  font-size: 1rem;
  height: 52px;
  line-height: 20px;
  padding: 0 16px;
  width: 100%;
  transition: box-shadow 0.2s ease-in-out, border-color 0.2s ease-in-out;
  text-align: start;
}

.input-container input:focus,
.input-container input:not(:placeholder-shown) {
  border: 2px solid #3081ee;
}

.input-label {
  position: absolute;
  background-color: #fff;
  color: rgb(99, 99, 99);
  font-size: 1rem;
  font-weight: 400;
  top: 16px;
  left: 12px;
  max-width: 90%;
  overflow: hidden;
  pointer-events: none;
  padding: 1px 6px;
  text-overflow: ellipsis;
  transform-origin: 0;
  transition: transform 0.15s ease-in-out, top 0.15s ease-in-out, color 0.15s ease-in-out, font-size 0.15s ease-in-out;
  white-space: nowrap;
  z-index: 1;
}

.input-container input:not(:placeholder-shown) + .input-label,
.input-container input:focus + .input-label {
  top: -8px;
  color: #3081ee;
  font-size: 0.9rem;
}

.eye-toggle-icon {
  cursor: pointer;
  position: absolute;
  top: 36%;
  right: 20px;
  width: 16px;
  height: 16px;
}

.action-button {
  width: 100%;
  height: 52px;
  border-radius: 6px;
  border: none;
  cursor: pointer;
  font-family: "Inter", sans-serif;
  font-size: 1rem;
  color: white;
  background-color: #3081ee;
  transition: background-color 0.15s ease-in-out;
}

.action-button:hover {
  background-color: #438dee;
}

.action-button:active {
  background-color: #1975ee;
}

.action-button.disabled,
.action-button.disabled:hover,
.action-button.disabled:active {
  cursor: not-allowed;
  background-color: #b0b0b0;
}

.auth-cta-text-container {
  display: flex;
  align-items: center;
  gap: 6px;
  flex-wrap: wrap;
  justify-content: center;
}

.auth-cta-text-container p {
  color: rgb(99, 99, 99);
}

.auth-cta-text-container a {
  text-decoration: none;
  color: #3081ee;
  transition: color 0.15s ease-in-out;
}

.auth-cta-text-container a:hover {
  color: #438dee;
}

.auth-cta-text-container.forgot-password {
  justify-content: left;
  margin-block: 4px;
  margin-left: 8px;
}

.auth-cta-text-container.t-and-c {
  margin-top: 64px;
  margin-bottom: 24px;
}

.or-line {
  width: 100%;
  height: 1px;
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.or-line .line {
  width: 100%;
  flex-grow: 1;
  height: 1px;
  background-color: #9ca1a7;
  margin: 0 2px;
}

.or-line p {
  background: white;
  padding: 4px 8px;
  font-size: 0.9rem;
  color: #9ca1a7;
}

.google-button {
  display: flex;
  align-items: center;
  gap: 20px;
  width: 100%;
  height: 52px;
  border-radius: 6px;
  border: 1px solid #9ca1a7 !important;
  border: none;
  cursor: pointer;
  font-size: 1rem;
  background-color: #fff;
  justify-content: center;
}

.google-button p {
  font-family: "Inter", sans-serif;
  justify-self: center;
}

.login-error-message,
.signup-error-message {
  color: #eb5252;
  font-size: 0.8rem;
  font-family: "DM Sans", sans-serif;
  margin: 0;
}

/* Signout.js */
.input-error-message {
  margin-block: -24px;
  padding: 0;
  color: red;
  font-family: "Inter", sans-serif;
  font-size: 0.8rem;
}

/* ForgotPassword.js */
.password-reset-message {
  text-align: center;
}

/* Popups */
.popup-container.no-background-blur {
  background-color: none !important;
  backdrop-filter: blur(0px);
}

.verify-email-popup .auth-cta-text-container {
  margin-top: -10px;
}

.verify-email-popup .auth-cta-text-container p {
  color: black;
}

.verify-email-popup .auth-cta-text-container span {
  color: #3081ee;
  transition: color 0.15s ease-in-out;
  cursor: pointer;
}

.verify-email-popup .auth-cta-text-container span:hover {
  color: #438dee;
}

/* Overriding browser autofill background color change on input field */
/* Chrome, Safari, and other WebKit-based browsers */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
  -webkit-text-fill-color: rgb(99, 99, 99) !important;
}

/* Firefox */
input:-moz-ui-autofill,
input:-moz-ui-autofill:hover,
input:-moz-ui-autofill:focus,
input:-moz-ui-autofill:active {
  background: white !important;
  color: rgb(99, 99, 99) !important;
}

/* Recaptcha.js */
.grecaptcha-badge {
  visibility: hidden;
}

.recaptcha-statement {
  color: grey;
  font-size: 0.6rem;
  text-align: center;
}

.recaptcha-statement a {
  color: grey;
  text-decoration: none;
}

/* Popup.js */
.popup-container {
  position: absolute;
  height: 100%;
  width: 100%;
  display: grid;
  place-content: center;
  z-index: 9000;
  background-color: rgba(17, 17, 17, 0.5);
  font-family: "Inter", "DM Sans", Arial, sans-serif;
}

.popup-content {
  overflow-y: auto;
  width: fit-content;
  height: fit-content;
  flex-grow: 1;
  background-color: #fff;
  box-shadow: 5px 5px 20px rgba(88, 87, 87, 0.3);
  border-radius: 10px;
  z-index: 6000;
  position: relative;
  transition: background-color 0.15s ease, color 0.15s ease;
}

.popup-content.popup-night-mode {
  background-color: #1c3252;
}

.popup-close-icon-container {
  width: 24px;
  height: 24px;
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;
}

.popup-close-icon {
  width: 24px;
  height: 24px;
  color: rgba(27, 37, 89, 1);
}

.popup-content.popup-night-mode .popup-close-icon {
  color: white;
}

.popup-overlay {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 80%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  z-index: 3000;
}

.small-popup-container {
  width: fit-content;
  padding: 52px 32px 32px 32px;
}
