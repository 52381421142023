/* src/App.css */
:root {
  --m: 19.5;
  --wavefreq: calc(50ms * var(--m));
}

body {
  margin: 0;
}
.home-page {
  text-align: center;
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.section {
  height: 100vh;
  /* padding: 50px; */
  border-bottom: 1px solid #ccc;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.app-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px;
  background-color: #f0f0f0;
}

.home-page button {
  margin-left: 10px;
}

/* Header.js */
.navbar {
  width: 100%;
}

.navbar-container {
  display: flex;
  position: fixed;
  width: 100%;
  top: 0px;
  z-index: 100;
  justify-items: center;
  align-items: center;
  padding-top: 16px;
  padding-bottom: 12px;
  background: rgba(255, 255, 255, 0.98);
}

.navbar-logo-container {
  flex-basis: 25%;
}

.navbar-logo {
  display: flex;
  align-items: center;
  min-width: fit-content;
  gap: 16px;
  padding-inline-start: 32px;
  cursor: pointer;
  text-decoration: none;
  width: fit-content;
}

.navbar-logo > img {
  width: auto;
  height: 2.5rem;
}

.navbar-logo > h3 {
  text-decoration: none;
  white-space: nowrap;
  width: fit-content;
  margin: 0;
  color: #1b2559;
  font-weight: 700;
  font-family: "Inter", sans-serif;
}

.navbar-elements {
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-basis: 75%;
}

.navbar-elements button,
.login-button {
  border: none;
  cursor: pointer;
  padding: 10px 20px;
  margin: 0 10px;
  font-size: 16px;
  outline: none;
}

.navbar-elements button:hover,
.login-button:hover {
  border: none;
  cursor: pointer;
  padding: 10px 20px;
  margin: 0 10px;
  font-size: 16px;
  outline: none;
}

.navbar-elements button:active,
.login-button:active {
  border: none;
  cursor: pointer;
  padding: 10px 20px;
  margin: 0 10px;
  font-size: 16px;
  outline: none;
}

.navbar-links button,
.login-button {
  background-color: transparent;
}

.signup-button {
  border-radius: 10px;
  background-color: #3081ee;
  color: white;
}

.navbar-links {
  display: flex;
  justify-content: center;
  gap: 4px;
  flex-basis: 67%; /* centers nav links */
}

.navbar-signup-login {
  display: flex;
  gap: 16px;
  flex-basis: 33%; /* centers nav links */
  justify-content: flex-end;
  padding-inline-end: 32px;
  white-space: nowrap;
}

/* Company.js */
.company-button > * {
  font-family: "Inter", sans-serif;
}

.logo-name h3 {
  text-shadow: none;
  box-shadow: none;
}

/* Header.js: Mobile Menu */
.mobile-menu-icon {
  display: none;
  color: #1b2559;
  z-index: 90;
  font-size: 32px;
}

@media (max-width: 1200px) {
  .mobile-menu-icon {
    display: block;
    cursor: pointer;
    margin-inline-end: 16px;
  }

  .navbar-container {
    justify-content: space-between;
  }

  .navbar-elements {
    flex-direction: column;
    gap: 8px;
    background: rgba(255, 255, 255, 0.98);
    position: absolute;
    right: 0;
    top: 60px;
    width: 0px;
    height: calc(100vh - 60px);
    transition: all 0.18s ease-in;
    overflow: hidden;
  }

  .navbar-elements.active {
    width: 300px; /* Arbitrary; width of mobile menu when open*/
  }

  .navbar-links {
    flex-direction: column;
    justify-content: flex-start;
    padding-block-start: 32px;
  }

  .navbar-signup-login {
    flex-direction: column;
    justify-content: center;
    gap: 8px;
    align-items: center;
    padding-inline-end: 0px;
  }

  .navbar-signup-login button {
    width: 80%;
  }
}

/* Banner.js */

.banner {
  margin-top: 68px;
  height: fit-content;
  padding-block: 4px;
  width: 100%;
  background: linear-gradient(90deg, rgba(126, 180, 254, 0.77) 0%, rgba(1, 131, 255, 0.9) 100%);
}

.banner-content {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding-bottom: 12px;
  text-align: center;
}

.banner-text-container p {
  color: white;
  font-family: "Inter";
  font-size: clamp(0.7rem, 2.7vw, 1rem);
  font-weight: 500;
}

.banner-button-container button {
  color: white;
  font-family: "Inter";
  font-size: clamp(0.6rem, 2.7vw, 0.8rem);
  font-weight: 300;
  display: grid;
  place-content: center;
  height: 32px;
  background-color: transparent;
  padding: 8px 24px;
  border-radius: 8px;
  border: 1px solid #e2e8f0;
  cursor: pointer;
}

@media (min-width: 640px) {
  .banner-content {
    display: flex;
    flex-direction: row;
    gap: 32px;
    padding-bottom: 0;
  }
}

/* End Banner.js */

/* Main.js (Landing Page) */

/* EmailSignup.js (in Main.js) */

.email-signup-container {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
}

.email-signup-container .icon-input-container {
  display: flex;
  justify-items: flex-end;
  align-items: center;
  width: 100%;
  max-width: 350px;
}

.email-signup-container .input-container {
  display: flex;
  align-items: center;
  flex-basis: 90%;
}

.icon-input-container .validation-icon {
  flex-basis: 10%;
  translate: -4px 0;
}

.input-container input {
  width: 100%;
  justify-self: end;
  border-radius: 10px;
  padding: 15px 10px;
}

.email-signup-container button {
  /* width: 50%; */
  max-width: 200px;
  flex-basis: 50%;
  cursor: pointer;
}

.email-popup-container {
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5); /* Semi-transparent black background */
  display: flex;
  justify-content: center;
  align-items: center;
}

.email-popup-contents {
  display: flex;
  position: absolute;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 40%;
  max-width: 350px;
  /* min-width */
  height: 220px;
  gap: 16px;
  border-radius: 8px;
  background: rgb(234, 233, 233);
  overflow: visible;
}

.email-popup-success-icon,
.email-popup-failure-icon {
  width: 64px;
  height: 64px;
  position: absolute;
  top: -32px; /* Half of icon height */
  background-color: white;
  border-radius: 100%;
  border: 1px solid white;
  z-index: 20;
}

.email-popup-success-icon {
  color: rgb(80, 159, 80);
}

.email-popup-failure-icon {
  color: rgb(196, 82, 82);
}

.email-popup-contents button {
  width: 80%;
  height: 20%;
  color: white;
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
  border-radius: 5px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin: 0px;
  translate: 0px 8px;
}

.email-popup-success-button {
  background-color: rgb(80, 159, 80);
  border: 2px solid rgb(80, 159, 80);
}

.email-popup-success-button:hover {
  background-color: rgb(107, 173, 107);
  border: 2px solid rgb(107, 173, 107);
}

.email-popup-failure-button {
  background-color: rgb(196, 82, 82);
  border: 2px solid rgb(196, 82, 82);
}

.email-popup-failure-button:hover {
  background-color: rgb(206, 112, 112);
  border: 2px solid rgb(206, 112, 112);
}

.email-popup-contents h3 {
  margin-top: 32px;
  margin-bottom: 10px;
  width: 80%;
  color: #1b2559;
  font: weight 2000px;
  font-family: "Inter", sans-serif;
  overflow: visible;
}
.email-popup-contents p {
  margin: 0px;
  width: 80%;
  overflow: visible;
  color: #68769f;
}

@media (max-width: 640px) {
  .email-signup-container {
    flex-direction: column;
    gap: 0px;
    width: 100%;
  }

  .email-signup-container .icon-input-container {
    max-width: none;
    width: 77%;
    translate: -5% 0;
    /* 
    Width and translate make email input same width as button
    while accounting for the space the icon takes up
    */
  }

  .email-signup-container .input-container {
    width: 70%;
    max-width: none;
    padding: 0px;
  }

  .email-signup-container button {
    width: 70%;
    max-width: none;
    margin-block: 3%;
  }

  .email-popup-contents {
    width: 70%;
    min-height: fit-content;
  }
}

/* Main.js Hero Section layout */

.hero-section-content-container {
  margin-top: 68px;
  display: flex;
  flex-direction: column;
  gap: 12px;
  align-content: center;
  justify-content: center;
  width: 100%;
  min-height: fit-content;
  overflow: hidden;
  text-align: center;
  border-bottom: 1px solid #ccc;
}

.hero-section-content-container h1 {
  font-size: clamp(2rem, 8vw, 4rem);
  color: black;
  width: 75%;
  align-self: center;
  word-wrap: break-word;
  font-family: "Inter", sans-serif;
  padding-inline: 32px;
  font-weight: 700;
  letter-spacing: -1.5px;
}

.hero-section-content-container span {
  color: #3081ee;
  font-weight: 900;
}

.hero-section-content-container p {
  font-family: "Inter", sans-serif;
  width: 45%;
  padding-inline: 32px;
  justify-self: center;
  align-self: center;
  margin-top: -24px;
  margin-bottom: 24px;
  line-height: 24px;
  color: black;
  letter-spacing: 0.5px;
}

.hero-section-content-container button {
  width: clamp(200px, 40vw, 300px);
  font-size: clamp(0.8rem, 2vw, 0.9rem);
  align-self: center;
  display: grid;
  place-items: center;
  padding: 18px;
  border-radius: 8px;
  border: none;
  background: #1863e5;
  color: white;
  font-family: "Inter", sans-serif;
  cursor: pointer;
}

/* .hero-section-content-container img {
  min-width: 250px;
  width: 50%;
  max-width: 500px;
  margin: 20px auto;
} */

.centered-image {
  width: 50%;
  max-width: 500px;
  display: block;
  margin: 20px auto;
}

.chatbot-animation-container {
  width: 80%;
  display: flex;
  justify-content: center;
  align-self: center;
  margin-block-start: 32px;
  margin-block-end: 64px;
}

/* ChatbotAnimation.js */
.chatbot-animation-content {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 32px;
  width: 60%;
  max-width: 1000px;
}

.chatbot-question-container {
  display: flex;
  position: relative;
  width: 100%;
}

.chatbot-question-container > .user-icon {
  align-self: center;
  color: #3992ef;
  background-color: transparent;
  border-radius: 100%;
  border: 1px solid #e2e8f0;
  padding: 12px 12px;
  flex-shrink: 0;
}

.chatbot-question-container .pen-icon {
  position: absolute;
  right: 24px;
  color: #718096;
  align-self: center;
}

.chatbot-question-input {
  display: flex;
  border-radius: 20px;
  border: 1px solid #e2e8f0;
  padding: 4px 24px;
  flex-grow: 1;
  margin-left: 16px;
}

.chatbot-question-input p {
  font-family: "Inter", sans-serif;
  font-size: clamp(0.8rem, 1vw, 1rem);
  font-weight: 400;
  color: #68769f;
  margin: 0;
  text-align: left;
  padding: 0;
  width: fit-content;
  padding-right: 32px;
  white-space: nowrap;
  overflow: hidden;
  animation: typeWriter 3s steps(40, end) forwards;
}

@keyframes typeWriter {
  from {
    width: 0;
  }
  to {
    width: 70%;
  }
}

.chatbot-response-container {
  display: flex;
  align-content: top;
  height: fit-content;
  opacity: 0;
  transition: opacity 1.5s ease-in-out;
}

.chatbot-animation-content .chatbot-response-container.visible {
  opacity: 1;
}

.chatbot-response {
  margin-left: 16px;
  width: 100%;
}

.chatbot-response-container > img {
  width: 24px;
  height: 24px;
  background-color: #c4defa99;
  border-radius: 100%;
  padding: 8px 11px;
}

.chatbot-response p {
  font-family: "Inter", sans-serif;
  font-size: clamp(0.8rem, 1vw, 1rem);
  width: 90%;
  font-weight: 400;
  color: #68769f;
  margin: 0;
  padding: 24px 24px;
  text-align: left;
  line-height: 24px;
  border-radius: 20px;
  background: #fff;
  box-shadow: -5px 16px 20px 5px rgba(112, 144, 176, 0.2);
}

@media (max-width: 768px) {
  .chatbot-animation-content .chatbot-response p {
    padding-right: 16px;
    /* width: clamp(80%, 80vw, 93%); */
  }
  .chatbot-animation-container {
    width: 100%;
  }
  .chatbot-animation-content {
    width: 95%;
  }
}

@media (max-width: 1024px) {
}

/* frequently asked questions page */

.subtext {
  margin-left: -5%;
}
.faqs-container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  width: 80%;
  margin-block-start: 24px;
  max-width: 1024px;
}

.faq-box {
  display: flex;
  flex-direction: column;
  padding-inline: 16px;
  border-radius: 20px;
  background-color: #f6f8fd;
  border: 1px solid #ccc;
}

.faq-content-top {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-inline: 8px;
  margin-top: 4px;
  gap: 16px;
}

.faq-content-top h3 {
  font-family: "Inter", sans-serif;
  color: #1b2559;
}

.faq-content-bottom {
  display: flex;
  justify-self: left;
  width: 90%;
  margin-inline: 8px;
}

.faq-content-bottom p {
  font-family: "Inter", sans-serif;
  color: #68769f;
  margin-top: -2px;
}

.faq-icon {
  cursor: pointer;
  width: 20px;
  height: 20px;
}

/* Section2.js (Features) */
.section2 {
  gap: 64px;
}

.section2 .features-subsection-container.subsection1 .subsection-headline-container,
.section2 .features-subsection-container.subsection3 .subsection-headline-container {
  text-align: left;
}

.section2 .features-subsection-container.subsection2 .subsection-headline-container,
.section2 .features-subsection-container.subsection4 .subsection-headline-container {
  align-self: end;
  text-align: right;
}

.section2 h1 {
  font-family: sans-serif;
  color: #1b2559;
  font-weight: bolder;
}

.section2 p {
  font-family: Plus Jakarta Sans, sans-serif;
  color: #68769f;
}

.features-subsection-container {
  display: flex;
  flex-direction: column;
  width: 80%;
  max-width: 1256px;
  gap: 64px;
}

.features-subsection-container .subsection-headline-container {
  width: 75%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.features-subsection-container .subsection-headline-container p {
  margin-top: -8px;
  line-height: 24px;
  font-style: normal;
  font-weight: 500;
}

.subsection1 {
  display: flex;
  flex-direction: column;
  margin-top: -32px;
}

.subsection1 .subsection-content-container {
  display: flex;
  position: relative;
  justify-content: center;
  width: 100%;
}

.subsection1 .subsection-content {
  position: absolute;
  top: 4%;
  background: white;
  width: 92%;
  height: 65%;
}

/* Macbook Image */
.features-subsection-container.subsection1 .subsection-content-container > img {
  width: 100%;
  height: auto;
  display: block;
}

/* Fake navbar in Section2.js subsection 1 computer screen */

.pseudo-navbar-container {
  display: flex;
  width: 100%;
  z-index: 11;
  justify-items: center;
  align-items: center;
  padding-top: 8px;
  padding-bottom: 8px;
  flex-basis: 10%;
  background: rgba(255, 255, 255, 0.98);
}

.pseudo-navbar-logo {
  display: flex;
  align-items: center;
  min-width: fit-content;
  flex-basis: 25%;
  gap: 16px;
  padding-inline-start: 12px;
}

.pseudo-navbar-logo > img {
  width: auto;
  height: 1.5rem;
}

.pseudo-navbar-logo > h3 {
  text-decoration: none;
  width: fit-content;
  margin: 0;
  color: #1b2559;
  font-weight: 700;
  font-family: "Inter", sans-serif;
}

.pseudo-navbar-elements {
  display: flex;
  width: 100%;
  justify-content: space-between;
  flex-basis: 75%;
}

.pseudo-mobile-menu-icon {
  display: none;
  color: #1b2559;
  font-size: 24px;
}

.pseudo-navbar-links {
  display: flex;
  justify-content: center;
  gap: 4%;
  flex-basis: 67%;
}

.pseudo-navbar-signup-login {
  display: flex;
  gap: 8%;
  flex-basis: 33%;
  justify-content: flex-end;
  align-items: center;
  padding-inline-end: 32px;
  white-space: nowrap;
}

.pseudo-navbar-elements p {
  color: #1b2559;
  font-size: 0.8rem;
}

.pseudo-signup {
  border-radius: 10px;
  background-color: #3081ee;
  color: white !important;
  padding: 4px 8px;
}

@media (max-width: 1024px) {
  .pseudo-navbar-container {
    justify-content: space-between;
    display: none; /* Uncomment if using fake screen for this section to show the mobile navbar */
  }

  .pseudo-navbar-elements {
    display: none;
  }

  .pseudo-mobile-menu-icon {
    display: block;
    margin-inline-end: 16px;
  }
}

/* End fake navbar */

.subsection1 .macbook-headline {
  display: flex;
  flex-direction: column;
  flex-basis: 15%;
  text-align: center;
  width: 100%;
  justify-self: center;
  margin-top: -16px;
}

.subsection1 .macbook-headline > h2 {
  font-family: sans-serif;
  color: #1b2559;
  width: 100%;
  align-self: center;
  white-space: nowrap;
}

.subsection1 .macbook-headline > p {
  margin-top: -10px;
  width: 75%;
  align-self: center;
}

@media (max-width: 1024px) {
  .subsection1 .macbook-headline > h2 {
    white-space: break-spaces;
  }

  .subsection1 .subsection-content-container > img {
    display: none !important; /* Hides the macbook picture */
  }

  .subsection1 .subsection-content-container {
    min-height: fit-content;
  }

  .subsection1 .subsection-content-container {
    display: flex;
    position: relative;
    justify-content: center;
    width: 100%;
  }

  .subsection1 .subsection-content {
    position: relative;
    top: 4%;
    background: white;
    width: 92%;
    height: 65%;
  }

  .subsection1 .card-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 32px;
    translate: 0px 24px;
  }

  .subsection1 .card-container .card1,
  .subsection1 .card-container .card2 {
    width: 70%;
  }
}

.subsection1 .card-container {
  display: flex;
  flex-basis: 70%;
  justify-content: space-evenly;
  justify-self: bottom;
  height: 62%;
}

@media (min-width: 1420px) {
  .subsection1 .card-container {
    translate: 0px 32px;
  }
}

.subsection1 .card-container .card1,
.subsection1 .card-container .card2 {
  flex-basis: 25%;
  display: flex;
  flex-direction: column;
  text-align: center;
  align-items: center;
  justify-content: center;
  padding: 12px;
  border-radius: 16px;
  background: #fff;
  box-shadow: 12px 24px 40px 4px rgba(112, 144, 176, 0.2);
}

.subsection1 .card-container .card1:hover,
.subsection1 .card-container .card2:hover {
  cursor: pointer;
}

.subsection1 .card1:hover .card1-img-container svg {
  position: absolute;
  visibility: visible;
  z-index: 1;
}

.card1-img-container svg {
  position: absolute;
  translate: -16px -16px;
  visibility: hidden;
  z-index: 1;
}

.subsection1 .card-container .card1 div > img {
  height: 128px;
  width: auto;
  align-self: center;
  z-index: 5;
}

.subsection1 .card-container .card2 {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.subsection1 .card-container .card2 .speech-bubble {
  position: relative;
}

.subsection1 .card-container .card2:hover {
  background: rgb(234, 242, 253, 1);
}

.subsection1 .card-container .card2 .speech-bubble p {
  position: absolute;
  max-width: 150px; /* To fit inside speech bubble svg (180px) */
  translate: 16px 45px; /* Centers p in speech bubble */
  line-height: 20px;
}

.subsection1 .card-container .card1 h3,
.subsection1 .card-container .card2 h3 {
  font-family: sans-serif;
  color: #1b2559;
  font-size: clamp(0.8rem, 2.5vw, 1rem);
}

.subsection1 .card-container .card1 p,
.subsection1 .card-container .card2 p {
  font-family: sans-serif;
  font-size: clamp(0.75rem, 2.5vw, 0.9rem);
}

/* Subsection 2 (Voice Integration) */
.features-subsection-container.subsection2 {
  display: flex;
  flex-direction: column;
}

.subsection2 .subsection-content-container {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.audio-select-container {
  display: flex;
  align-items: center;
  gap: 16px;
  flex-wrap: wrap;
}

.audio-select-container select {
  background-color: #f0f0f0;
  border: 1px solid #d1d1d1;
  border-radius: 4px;
  font-family: "Inter", sans-serif;
  letter-spacing: -0.5px;
  font-size: 14px;
  padding: 4px 8px;
  width: 100%;
  -webkit-appearance: none; /* Removes default styling on iOS */
  -moz-appearance: none; /* Removes default styling on Firefox */
  appearance: none; /* Standard way to remove default styling */
  cursor: pointer;
  width: 250px;
  z-index: 1;
}

.audio-select {
  position: relative;
}

.audio-select-icon {
  width: 14px;
  height: 14px;
  color: black;
  position: absolute;
  bottom: 7px;
  right: 4px;
  z-index: 5;
  cursor: pointer;
}

.subsection2 .soundwave-container {
  display: flex;
  gap: clamp(6px, 2vw, 32px);
  align-items: center;
  overflow: hidden;
}

.play-icon {
  color: #3992ef;
  width: 32px;
  height: 32px;
  cursor: pointer;
  flex-shrink: 0;
}

.sound-wave {
  display: flex;
  gap: 4px;
  height: 100px;
  width: 100%;
  max-width: 500px;
  align-items: center;
}

.wave {
  min-width: 1px;
  width: 2px;
  max-width: 3px;
  height: clamp(35px, 10vh, 55px);
  background-color: #3992ef;
  transform-origin: center;
  transition: transform 0.25s ease-in-out;
}

/* Subsection 3 */
.features-subsection-container.subsection3 {
  display: flex;
  flex-direction: column;
  width: 80%;
}

.subsection3 .subsection-headline-container {
  width: 100%;
}

.subsection3 .subsection-content-container {
  margin-top: -32px;
}

.subsection3 .card-container {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  grid-template-rows: repeat(12, 1fr);
  width: 100%;
  min-height: fit-content;
  height: 90%;
  overflow: visible;
}

.subsection3 .card-container .card {
  align-self: center;
  justify-self: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 320px;
  width: 300px;
  height: fit-content;
  border-radius: 12px;
  padding: 32px 24px;
  background: #fff;
  box-shadow: 0px 8px 24px 4px rgba(112, 144, 176, 0.2);
}

.subsection3 .card-container > :first-child,
.subsection3 .card-container > :nth-child(3) {
  opacity: 0.3;
  z-index: 4;
}

.subsection3 .card-container > :first-child {
  grid-column: 1/5;
  grid-row: 2/-2;
  min-height: 300px;
}

.subsection3 .card-container > :nth-child(2) {
  grid-column: 5/9;
  grid-row: 1/-1;
  min-height: 340px;
  z-index: 5;
}

.subsection3 .card-container > :nth-child(3) {
  grid-column: 9/-1;
  grid-row: 2/-2;
  min-height: 300px;
}

.subsection3 .card-container .card .card-content {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 90%;
  height: 90%;
  align-items: center;
  text-align: center;
  justify-content: center;
  margin-bottom: -16px;
}

.subsection3 .card-container .card img {
  width: auto;
  max-height: 180px;
  padding: 16px;
  flex-basis: 70%;
}

.subsection3 .card-container .card div {
  flex-basis: 30%;
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
}

.subsection3 .card-container .card .card-content h4 {
  font-family: sans-serif;
  color: #1b2559;
  font-size: 1.5rem;
  width: 100%;
  margin: 0;
  font-style: normal;
  font-weight: 700;
  line-height: 100%;
}

.subsection3 .card-container .card .card-content p {
  color: #718096;
  font-size: 0.8rem;
  font-style: normal;
  font-weight: 500;
  line-height: 24px;
  width: 100%;
}

.fade-in {
  opacity: 1;
  transition: opacity 0.4s ease-in;
}

.fade-out {
  opacity: 0;
  transition: opacity 0.4s ease-out;
}

@media (min-width: 0px) {
  .subsection3 .card-container .card {
    margin-inline: -48px;
    max-height: 50vh;
  }

  .subsection3 .card-container > :first-child {
    grid-column: 2/6;
  }

  .subsection3 .card-container > :nth-child(2) {
    grid-column: 4/10;
  }

  .subsection3 .card-container > :nth-child(3) {
    grid-column: 8/-2;
  }
}

@media (min-width: 500px) {
  .subsection3 .card-container .card {
    margin-inline: -48px;
    max-height: 60%;
    padding: 12px 0px;
  }

  .subsection3 .card-container > :first-child {
    grid-column: 2/5;
  }

  .subsection3 .card-container > :nth-child(2) {
    grid-column: 4/10;
  }

  .subsection3 .card-container > :nth-child(3) {
    grid-column: 9/-2;
  }
}

@media (min-width: 640px) {
  .subsection3 .card-container .card {
    max-height: 65%;
    padding: 0px 16px;
  }

  .subsection3 .card-container > :first-child {
    grid-column: 2/7;
  }

  .subsection3 .card-container > :nth-child(2) {
    grid-column: 4/10;
  }

  .subsection3 .card-container > :nth-child(3) {
    grid-column: 8/-2;
  }
}

@media (min-width: 800px) {
  .subsection3 .card-container .card {
    padding: 0px 24px;
  }

  .subsection3 .card-container > :first-child {
    grid-column: 2/6;
  }

  .subsection3 .card-container > :nth-child(2) {
    grid-column: 5/9;
  }

  .subsection3 .card-container > :nth-child(3) {
    grid-column: 8/-2;
  }
}

@media (min-width: 1024px) {
  .subsection3 .card-container > :first-child {
    grid-column: 2/5;
  }

  .subsection3 .card-container > :nth-child(2) {
    grid-column: 5/9;
  }

  .subsection3 .card-container > :nth-child(3) {
    grid-column: 9/-2;
  }
}

@media (min-width: 1256px) {
  .subsection3 .card-container {
    gap: 32px;
  }

  .subsection3 .card-container > :first-child {
    max-width: 90%;
    grid-column: 1/5;
  }

  .subsection3 .card-container > :nth-child(2) {
    max-width: 90%;
    grid-column: 5/9;
  }

  .subsection3 .card-container > :nth-child(3) {
    max-width: 90%;
    grid-column: 9/-1;
  }
}

/* Subsection 4 */
.features-subsection-container.subsection4 {
  display: flex;
  flex-direction: column;
}

.subsection4 .subsection-content-container {
  display: flex;
  justify-content: center;
  min-height: fit-content;
  margin-top: -32px;
  min-height: 350px;
}

.subsection4 .subsection-content-container .content1 {
  flex-basis: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  align-self: center;
}

.subsection4 .subsection-content-container .content1 img {
  height: auto;
  max-width: 70%;
}

.subsection4 .subsection-content-container .content2 {
  flex-basis: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.subsection4 .subsection-content-container .content2 .card {
  min-height: fit-content;
  max-width: 90%;
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  text-align: center;
  border-radius: 16px;
  background: #fff;
  box-shadow: 12px 24px 40px 4px rgba(112, 144, 176, 0.3);
}

.subsection4 .subsection-content-container .content2 .card > img {
  height: auto;
  max-width: 60%;
  margin-top: 24px;
}

.subsection4 .subsection-content-container .content2 .card div {
  width: 80%;
}

.subsection4 .subsection-content-container .content2 .card h2 {
  font-family: sans-serif;
}

.subsection4 .subsection-content-container .content2 .card span {
  color: #f7b32f;
}

@media (min-width: 0px) {
  .subsection4 .subsection-content-container {
    display: flex;
    flex-direction: column;
  }

  .subsection4 .subsection-content-container .content2 {
    margin-top: 32px;
  }

  .subsection4 .subsection-content-container .content2 .card {
    justify-content: space-evenly;
    min-height: 280px;
  }
}

@media (min-width: 768px) {
  .subsection4 .subsection-content-container {
    display: flex;
    flex-direction: row;
  }

  .subsection4 .subsection-content-container .content2 {
    margin-top: 0px;
  }
}

/* End Section2.js */

/*  Section3.js (Pricing) */

.pricing-header {
  font-family: "Inter", sans-serif;
  color: #3081ee;
  font-size: clamp(2rem, 8vw, 3rem);
  text-align: center;
  font-weight: 700;
  width: 100%;
}

.pricing-header h1 {
  margin: 0;
  margin-bottom: 16px;
}

.cancel-anytime {
  display: flex;
  align-items: center;
  gap: 12px;
  margin-bottom: 16px;
}

.cancel-anytime .check-icon {
  color: #1863e5;
}

.cancel-anytime p {
  font-family: "DM Sans", sans-serif;
  font-size: clamp(0.9rem, 2vw, 1rem);
}

.price-selector-container {
  display: flex;
  position: relative;
}

.section3 .price-selector-container {
  transform: scale(0.92);
  margin-bottom: -48px;
}

@media (min-width: 500px) {
  .section3 .price-selector-container {
    margin-bottom: -32px;
  }
}

.price-selector-container .fancy-text {
  position: absolute;
  font-family: "Dancing Script", sans-serif;
  color: black;
  font-size: clamp(1.2rem, 2vw, 1.4rem);
  right: -70px;
  top: -40px;
  transform: rotate(-10deg);
}

@media (min-width: 500px) {
  .price-selector-container .fancy-text {
    right: -95px;
    top: -45px;
  }
}

.price-selector-container .curve {
  position: absolute;
  right: -20px;
}

.price-selector {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  font-family: "DM Sans", sans-serif;
  font-size: 1rem;
  font-weight: 300;
  color: rgba(23, 15, 73, 1);
}

/* Slider */
/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 72px;
  height: 28px;
}

/* Hide default HTML checkbox */
.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

/* The slider */
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #8ab1e4;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .slider {
  background-color: #3081ee;
}

input:focus + .slider {
  box-shadow: 0 0 1px #3081ee;
}

input:checked + .slider:before {
  -webkit-transform: translateX(43px);
  -ms-transform: translateX(43px);
  transform: translateX(43px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

/* Pricing Plan Cards */

.pricing-plans-container {
  display: flex;
  width: 100%;
  gap: clamp(32px, 5vw, 72px);
  flex-wrap: wrap-reverse;
  justify-content: center;
}

#section3 .pricing-plans-container {
  transform: scale(0.8);
}

.pricing-plans-container * {
  font-family: "DM Sans", sans-serif;
}

.pricing-plan {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  border-radius: 24px;
  border: 1px solid #eff0f6;
  background-color: #fff;
  box-shadow: 0px 2px 12px 0px rgba(20, 20, 43, 0.08);
  padding: 32px clamp(12px, 5vw, 32px);
  box-sizing: border-box;
  width: clamp(280px, 75vw, 420px);
  position: relative;
  transition: background-color 0.2s ease;
}

.pricing-plan:hover {
  background-color: #3081ee;
  transition: background-color 0.2s ease;
}

.most-popular {
  position: absolute;
  top: 16px;
  right: 16px;
  padding-inline: 8px;
  border-radius: 8px;
  background-color: rgba(48, 129, 238, 0.4);
}

.most-popular > p {
  font-size: 0.7rem;
  color: rgba(23, 15, 73, 1);
}

.pricing-plan:hover .most-popular {
  background-color: rgba(255, 255, 255, 0.2);
}

.pricing-plan:hover .most-popular > p {
  color: white;
}

.pricing-title {
  display: flex;
  align-items: center;
  gap: 16px;
  font-size: 1.5rem;
  color: #170f49;
}

.pricing-plan:hover .pricing-title {
  color: white;
}

.pricing-icon {
  display: flex;
  width: fit-content;
  padding: 12px;
  border-radius: 10px;
  background-color: rgba(236, 235, 255, 1);
}

.pricing-subtitle {
  color: #6f6c90;
  font-size: 1rem;
  line-height: 24px;
  flex-grow: 1;
}

.pricing-plan:hover .pricing-subtitle {
  color: #d9dbe9;
}

.pricing-plan .price {
  font-size: 2.5rem;
  font-weight: 600;
  letter-spacing: 2px;
  height: fit-content;
  color: #170f49;
  flex-grow: 1;
}

.pricing-plan:hover .price {
  color: white;
}

.pricing-plan .price p {
  margin: 0;
  padding: 0;
}

.pricing-plan .price span {
  color: #6f6c90;
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: 0px;
}

.pricing-plan:hover .price span {
  color: #d9dbe9;
}

.pricing-plan-features {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: left;
}

.pricing-plan-features > div {
  display: flex;
  align-items: center;
  gap: 8px;
  height: 36px;
}

.pricing-plan-features > p {
  color: rgba(23, 15, 73, 1);
  font-weight: 600;
  font-size: 1rem;
}

.pricing-plan:hover .pricing-plan-features > p {
  color: white;
}

.pricing-plan-features > div > p {
  font-size: 0.95rem;
  font-weight: 200;
  letter-spacing: 0.3px;
  color: rgba(23, 15, 73, 1);
}

.pricing-plan:hover .pricing-plan-features > div > p {
  color: #eff1ff;
}

.pricing-plan-features .feature-icon {
  height: 20px;
  width: 20px;
}

.pricing-plan-features .included.feature-icon {
  color: rgba(48, 129, 238, 1);
}

.pricing-plan:hover .pricing-plan-features .included.feature-icon {
  color: white;
}

.pricing-plan-features .unincluded.feature-icon {
  color: rgb(128, 128, 128);
}

.pricing-plan:hover .pricing-plan-features .unincluded.feature-icon {
  color: rgb(185, 185, 185);
}

.pricing-plan-button {
  width: 100%;
  margin-top: clamp(16px, 5vh, 32px);
  height: fit-content;
}

.pricing-plan-button button {
  width: 75%;
  padding-block: 16px;
  border-radius: 32px;
  display: grid;
  place-content: center;
  margin: auto;
  border: none;
  color: white;
  font-size: 1rem;
  cursor: pointer;
  background-color: rgba(48, 129, 238, 1);
}

.pricing-plan:hover .pricing-plan-button button {
  background-color: white;
  color: rgba(48, 129, 238, 1);
}

.pricing-plans-container-dashboard {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  gap: clamp(32px, 5vw, 72px);
  flex-wrap: wrap-reverse;
  justify-content: center;
  margin-top: 1px;
}

.pricing-plans-container-dashboard * {
  font-family: "DM Sans", sans-serif;
}

.pricing-plan-dashboard {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  border-radius: 24px;
  height: 530px;
  border: 1px solid #eff0f6;
  background-color: #fff;
  box-shadow: 0px 2px 12px 0px rgba(20, 20, 43, 0.08);
  padding: 32px clamp(12px, 5vw, 32px);
  box-sizing: border-box;
  width: clamp(75%, 80vw, 100%);
  position: relative;
  transition: background-color 0.2s ease;
  z-index: 3000;
}

@media (min-width: 1024px) {
  .pricing-plans-container-dashboard {
    flex-direction: row;
  }

  .pricing-plan-dashboard {
    width: calc(50% - clamp(16px, 2.5vw, 36px));
  }
}

.pricing-plan-dashboard:hover {
  background-color: #3081ee;
  transition: background-color 0.2s ease;
}

.pricing-plan-dashboard:hover .most-popular {
  background-color: rgba(255, 255, 255, 0.2);
}

.pricing-plan-dashboard:hover .most-popular > p {
  color: white;
}

.pricing-subtitle-dashboard {
  color: #6f6c90;
  font-size: 1rem;
  line-height: 20px;
  flex-grow: 1;
  margin: 0;
}
.pricing-subtitle-dashboard p {
  margin-bottom: 0px;
}
.pricing-plan:hover .pricing-subtitle-dashboard {
  color: #d9dbe9;
}
.pricing-plan-dashboard:hover .pricing-subtitle {
  color: #d9dbe9;
}

.pricing-plan-dashboard:hover .pricing-subtitle-dashboard {
  color: #d9dbe9;
}

.pricing-plan-dashboard .price {
  font-size: 2.5rem;
  font-weight: 600;
  letter-spacing: 2px;
  height: fit-content;
  color: #170f49;
}

.pricing-plan-dashboard:hover .price {
  color: white;
}
.pricing-plan-dashboard .price p {
  margin: 0;
  padding: 0;
}

.pricing-plan-dashboard .price span {
  color: #6f6c90;
  font-size: 1rem;
  font-weight: 400;
  letter-spacing: 0px;
}
.pricing-plan-dashboard:hover .price span {
  color: #d9dbe9;
}

.pricing-plan-dashboard:hover .pricing-plan-features > p {
  color: white;
}
.pricing-plan-dashboard:hover .pricing-plan-features > div > p {
  color: #eff1ff;
}
.pricing-plan-dashboard:hover .pricing-plan-features .included.feature-icon {
  color: white;
}
.pricing-plan-dashboard:hover .pricing-plan-features .unincluded.feature-icon {
  color: rgb(185, 185, 185);
}
.pricing-plan-button-dashboard {
  width: 100%;
  margin-top: clamp(10px, 1vh, 32px);
  height: fit-content;
}

.pricing-plan-button-dashboard button {
  width: 75%;
  padding-block: 16px;
  border-radius: 32px;
  display: grid;
  place-content: center;
  margin: auto;
  border: none;
  color: white;
  font-size: 1rem;
  cursor: pointer;
  background-color: rgba(48, 129, 238, 1);
}

.pricing-plan:hover .pricing-plan-button-dashboard button {
  background-color: white;
  color: rgba(48, 129, 238, 1);
}

.pricing-plan-dashboard:hover .pricing-plan-button button {
  background-color: white;
  color: rgba(48, 129, 238, 1);
}
.pricing-plan-dashboard:hover .pricing-plan-button-dashboard button {
  background-color: white;
  color: rgba(48, 129, 238, 1);
}

/* Pricing Plan in Dashboard */

.section-pricing-popup {
  /* This class prefixes the dashboard pricing plan, for night mode */
  position: relative;
  height: fit-content;
  padding-block: 1px;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  transform: scale(0.85);
}

.section-pricing-popup .price-selector-container {
  margin-bottom: 16px;
}

.section-pricing-popup.night-mode-pricing .fancy-text,
.section-pricing-popup.night-mode-pricing .price-selector > p {
  color: white;
}

/* Carousel.js */
.carousel-container {
  width: 100%;
  height: fit-content;
  position: relative;
  justify-content: center;
  padding-block: 32px;
}

.carousel-buttons {
  position: absolute;
  height: fit-content;
  display: flex;
  align-items: center;
  gap: 32px;
  bottom: -5%;
  right: 5%;
}

@media (min-width: 578px) {
  .carousel-buttons {
    top: -10%;
    right: 5%;
  }
}

.carousel-content {
  display: flex;
  gap: 32px;
  overflow-x: scroll;
  scroll-snap-type: x mandatory;
  margin-inline: 16px;
}

.carousel-content::-webkit-scrollbar {
  display: none;
}

.carousel-left-arrow {
  font-size: 1.5rem;
  color: rgba(74, 85, 104, 1);
  background-color: transparent;
  cursor: pointer;
}

.carousel-right-arrow {
  font-size: 2rem;
  color: #1863e5;
  background-color: transparent;
  cursor: pointer;
}

/* End Carousel.js */

/* Testimonial.js */
.testimonials-section {
  margin: auto;
  padding-block: 64px;
  width: 90%;
  height: 100%;
  border-bottom: 1px solid #ccc;
}

.testimonials-header {
  font-family: "Inter", sans-serif;
  color: black;
  font-size: clamp(2rem, 8vw, 4rem);
  font-weight: 700;
  width: 85%; /* So header doesn't overlap carousel buttons */
}

.testimonials-header span {
  font-family: "Inter", sans-serif;
  color: rgba(48, 129, 238, 1);
}

.testimonial-content {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  gap: 4px;
  border: 1px solid #f3f3f3;
  border-radius: 12px;
  padding: 24px;
  min-width: clamp(250px, 30vw, 350px);
  min-height: clamp(220px, 5vh, 300px);
  background: #fff;
  font-family: "DM Sans", sans-serif;
}

.testimonial-content * {
  margin: 0;
}

.testimonial-text {
  font-size: clamp(0.8rem, 3vw, 1rem);
  font-weight: 300;
  line-height: 24px;
  flex-grow: 1;
  place-content: center;
  align-self: start;
}

.testimonial-info {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.testimonial-info p {
  font-size: clamp(0.8rem, 2vw, 0.9rem);
}

.testimonial-name-age {
  color: black;
  font-weight: 500;
}

.testimonial-location {
  color: #171923;
  font-weight: 300;
}

.stars {
  display: flex;
  gap: 2px;
  color: #1863e5;
}
/* End Testimonial.js */

/* Section4.js: Team Members */
.team-section h1 {
  width: 100%;
  text-align: center;
  font-family: "Inter", sans-serif;
  color: black;
  font-size: clamp(2rem, 8vw, 4rem);
  font-weight: 700;
}

.team-header span {
  color: #3081ee;
  font-weight: 900;
}

.team-container {
  width: 100%;
}

.team-member-card {
  display: flex;
  flex-direction: column;
  height: 69vh;
  width: clamp(80vw, 50vw, 650px);
  background: #101010;
  border-radius: 16px;
  overflow: hidden;
  position: relative;
}

.team-member-img-container {
  object-fit: contain;
  overflow: hidden;
  margin-block: 10%;
  height: 300px;
  justify-content: center;
  display: flex;
  margin: 0;
}

.team-member-img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.team-member-info {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding-inline: 16px;
  overflow-y: auto;
}

.team-member-info .team-member-name {
  font-family: "Inter", sans-serif;
  color: white;
  font-weight: 600;
  font-size: clamp(1.7rem, 1vw, 2.4rem);
  margin: 0;
  margin-top: 16px;
  margin-bottom: 8px;
}

.team-member-info .team-member-title {
  font-family: "Inter", sans-serif;
  color: white;
  font-weight: 500;
  font-size: clamp(1.3rem, 1vw, 2rem);
  margin: 0;
}

.team-member-info .team-member-description {
  font-family: "Inter", sans-serif;
  color: #868686;
  font-weight: 400;
  display: flex;
  align-items: center;
  font-size: clamp(0.75rem, 1vw, 1rem);
  margin: 0;
}

.team-member-info .team-member-social-icon {
  width: 96px;
  height: auto;
}

@media (min-width: 550px) {
  .team-member-card {
    display: flex;
    flex-direction: row;
    height: clamp(300px, 70vh, 400px);
    width: clamp(450px, 70vw, 650px);
  }

  .team-member-img-container {
    height: 100%;
    width: clamp(300px, 70vw, 400px);
  }

  .team-member-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 70%;
  }

  .team-member-description {
    height: fit-content;
  }
}

#team .carousel-item.active {
  transform: scaleY(1);
  transition: transform 0.3s ease;
}

/* #team .carousel-item.inactive {
  transform: scaleY(0.85);
  transition: transform 0.3s ease;
} */

/* #team .carousel-item.inactive .inactive-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  
  backdrop-filter: blur(10px);
  transition: backdrop-filter 0.3s ease;
  z-index: 10;
} */

/* End Section4.js */
.team-section,
.section3,
.section2,
.section1 {
  position: relative;
  height: fit-content;
  padding-block: 64px;
  /* border-bottom: 1px solid #ccc; */
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.header-text-center {
  width: 100%;
  white-space: break-spaces;
  text-align: center;
}

.header-text-center h1 {
  font-family: "Inter", sans-serif;
  color: #3081ee;
  font-size: clamp(3rem, 8vw, 4rem);
  font-weight: 700;
  letter-spacing: -2px;
}

/* Top Button */
.top-button {
  position: fixed;
  bottom: 2%;
  right: 2%;
  background-color: rgba(48, 129, 238, 1);
  color: #fff;
  padding: 10px 14px;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  z-index: 10;
  font-size: 18px;
}

/* End Top Button */

/* SignUpCTASection.js */

.signup-cta-section {
  width: 100%;
  background-color: rgba(48, 129, 238, 1);
  border-radius: 12px;
  padding-bottom: 32px;
}

.signup-cta-content {
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 100%;
  height: 100%;
  margin-block: 32px;
}

.signup-cta-content .text-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-family: "Inter", sans-serif;
  flex-grow: 1;
  flex-basis: 67%;
}

.signup-cta-content .text-content header {
  width: 100%;
  font-size: clamp(1.5rem, 4vw, 3rem);
  text-align: center;
  letter-spacing: -3px;
}

.signup-cta-content .text-content .t-and-c {
  color: white;
  font-size: clamp(0.75rem, 2vw, 0.9rem);
  text-align: center;
}

.signup-cta-content .text-content a {
  text-decoration: none;
  color: #333954;
}

.signup-cta-buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 32px;
  height: 128px;
  width: 100%;
  justify-content: center;
  margin-bottom: 16px;
}

.plain-signup-button {
  background-color: #777;
  color: white;
  border-radius: 8px;
  padding-inline: 24px;
  height: 100%;
  width: 200px;
  display: grid;
  place-content: center;
  text-align: center;
  border: none;
  cursor: pointer;
}

.google-signup-button {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border-radius: 8px;
  padding-inline: 24px;
  height: 100%;
  width: 200px;
  gap: 4px;
  border: none;
  cursor: pointer;
}

.signup-cta-content .signup-cta-media {
  display: grid;
  place-content: center;
  flex-basis: 33%;
}

@media (min-width: 760px) {
  .signup-cta-content {
    flex-direction: row;
    gap: 0px;
  }

  .signup-cta-buttons {
    flex-direction: row;
    height: 48px;
  }
}
/* End SignUpCTASection.js */

/* SocialProofPopup.js */

.social-proof-popup {
  height: fit-content;
  width: clamp(fit-content, 1vw, 300px);
  position: fixed;
  margin-inline: auto;
  bottom: 1%;
  left: 50%;
  transform: translate(-50%, 0%);
  transition: opacity 1s;
}

.social-proof-popup.fade-in {
  opacity: 1;
}

.social-proof-popup.fade-out {
  opacity: 0;
}

.social-proof-content {
  background-color: white;
  padding-inline: 12px;
  border-radius: 8px;
  border: 1px solid lightgrey;
  display: grid;
  place-content: center;
  font-family: "Inter", sans-serif;
  font-size: clamp(0.6rem, 2vw, 0.9rem);
  text-align: center;
}

.social-proof-content span {
  font-weight: 700;
}

/* End SocialProofPopup.js */

/* Footer.js */
footer {
  display: flex;
  justify-content: center;
}

.footer-content-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  max-width: 1500px;
}

.footer-main-content {
  display: flex;
  flex-direction: column;
  padding: 32px;
}

.footer-main-content * {
  font-family: "DM Sans", sans-serif;
}

.footer-logo-container {
  display: flex;
  gap: 16px;
  align-items: center;
  margin-bottom: 32px;
}

.footer-logo-container > img {
  width: auto;
  height: 40px;
}

.footer-logo-container > h3 {
  color: #1b2559;
  font-size: 1.5rem;
  font-weight: 700;
  font-family: "Inter", sans-serif;
}

.footer-links-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  gap: 16px;
}

@media (max-width: 488px) {
  .footer-links-container {
    justify-content: flex-start;
  }
}

.footer-link-container {
  display: flex;
  flex-direction: column;
  text-align: start;
  width: 200px;
}

.footer-link-header {
  color: black;
  font-size: 0.8rem;
  font-weight: 500;
}

.footer-link {
  display: flex;
  align-items: center;
  gap: 8px;
  text-decoration: none;
  color: #4a5568;
  font-size: 0.8rem;
  transition: font-weight 0.2s ease-in-out;
}

.footer-link:hover {
  font-weight: 600;
}

.footer-link > p {
  margin-block: 8px;
}

.footer-link > span {
  border-radius: 6px;
  padding: 1px 4px;
  display: grid;
  place-content: center;
  font-size: 0.6rem;
  color: white;
  background: linear-gradient(90deg, #28a6ff 0.22%, #1804fb 99.77%);
}

.copyright-text {
  width: 100%;
  text-align: center;
  font-family: "Inter", sans-serif;
  color: #68769f;
  font-size: 0.7rem;
}

/* EmailVerified.js */
.email-verified-page {
  height: 90vh;
  display: grid;
  place-content: center;
}

.email-verified-content {
  margin-block: auto;
  gap: 12px;
  width: 320px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #fff;
  padding: 32px;
  box-shadow: 5px 5px 20px rgba(88, 87, 87, 0.2);
  border-radius: 16px;
}

.email-verified-content .company-logo-container > * {
  margin: 0;
  padding: 0;
}

.email-verified-content button {
  width: clamp(200px, 40vw, 300px);
  font-size: clamp(0.8rem, 2vw, 0.9rem);
  align-self: center;
  display: grid;
  place-items: center;
  padding: 18px;
  border-radius: 8px;
  border: none;
  background: #1863e5;
  color: white;
  font-family: "Inter", sans-serif;
  cursor: pointer;
}

.email-verified-content button:hover {
  background: #2873f5;
}

.email-verified-content button:active {
  background: #175cd4;
}

/***** Blog.js *****/
.blog-page-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 72px;
}

.blog-preview-list-container {
  display: flex;
  width: 100%;
  margin-block: 64px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 48px;
  max-width: 2000px;
  margin-inline: auto;
}

.blog-preview-container {
  width: 80%;
  max-width: 400px;
  height: fit-content;
  display: flex;
  place-content: center;
  box-shadow: 0px 2px 12px 0px rgba(20, 20, 43, 0.08);
  border-radius: 16px;
  overflow: hidden;
  transition: transform 0.2s ease;
}

.blog-preview-container > * {
  font-family: "Inter", sans-serif;
}

.blog-preview-content {
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
  justify-content: space-between;
}

.blog-preview-container:hover {
  cursor: pointer;
  transform: scale(1.008);
}

.blog-preview-image {
  flex-basis: 100%;
  height: 40%;
}

.blog-preview-text-content > * {
  margin: 0;
  word-wrap: break-word;
  overflow-wrap: break-word;
}

.blog-preview-text-content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 90%;
  height: 60%;
  margin-inline: auto;
}

.blog-preview-title {
  color: #121416;
  font-weight: 400;
  font-size: clamp(1.3rem, 1.4vw, 1.5rem);
  margin-bottom: 12px;
}

.blog-preview-subtitle {
  color: #6c757d;
  font-weight: 400;
  line-height: 22px;
  font-size: clamp(0.9rem, 1.8vw, 0.95rem);
}

@media (min-width: 1024px) {
  .blog-preview-container {
    height: 200px;
    max-width: 1000px;
  }

  .blog-preview-content {
    display: flex;
    flex-direction: row;
    height: 100%;
    width: 100%;
  }

  .blog-preview-image {
    flex-basis: 30%;
    height: 100%;
  }

  .blog-preview-text-content {
    flex-basis: 65%;
    height: 100%;
    flex-shrink: 5;
  }
}

.blog-preview-image > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.blog-preview-author-container {
  display: flex;
  gap: 16px;
  align-items: center;
}

.blog-preview-author-container > img {
  width: 36px;
  height: 36px;
  border-radius: 100%;
}

.blog-preview-author-date {
  display: flex;
  gap: 8px;
  align-items: center;
}

.blog-preview-author-name {
  color: #121416;
  font-weight: 400;
  line-height: 30px;
  font-size: 0.9rem;
}

.blog-preview-date {
  color: #6c757d;
  font-weight: 400;
  line-height: 30px;
  font-size: 0.9rem;
}

.blog-preview-dot {
  color: #6c757d;
  font-weight: 400;
  line-height: 40px;
  font-size: 0.8rem;
}

@media (max-width: 430px) {
  .blog-preview-author-date {
    display: flex;
    flex-direction: column;
    gap: 0;
    align-items: flex-start;
  }

  .blog-preview-author-date > * {
    line-height: 4px;
  }

  .blog-preview-dot {
    display: none;
  }
}

/***** BlogTemplate.js *****/
.blog-template-page {
  display: flex;
  flex-direction: column;
}

.blog-template-hero-section {
  position: relative;
  height: clamp(300px, 80vh, 550px);
  overflow: hidden;
  margin-top: 72px;
}

.blog-template-hero-section img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
}

.blog-template-hero-section-content {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  box-sizing: border-box;
}

.blog-template-hero-section-content > * {
  font-family: "Inter", sans-serif;
  color: white;
}

.blog-template-content-container > * {
  font-family: "Inter", sans-serif;
}

.blog-template-back-container {
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  gap: 12px;
  margin-left: 5%;
  margin-top: 24px;
  cursor: pointer;
  color: white;
}

.blog-template-back-container:hover .blog-template-back-icon {
  transform: translateX(-8px);
}

.blog-template-back-icon {
  font-size: 18px;
  font-weight: 400;
  transition: transform 0.3s ease;
}

.blog-template-hero-section-text-content {
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 80%;
  max-width: 1000px;
  margin-left: 5%;
  margin-bottom: 16px;
}

.blog-template-title {
  font-weight: 400;
  line-height: 40px;
  font-size: 2rem;
}

.blog-template-subtitle {
  color: rgb(210, 210, 210);
  font-weight: 400;
  line-height: 30px;
  font-size: 1rem;
}

.blog-template-author-date {
  display: flex;
  gap: 12px;
  color: rgb(210, 210, 210);
}

@media (max-width: 320px) {
  .blog-template-dot {
    display: none;
  }

  .blog-template-author-date {
    display: flex;
    flex-direction: column;
    gap: 0;
  }
}

.blog-template-content-container {
  width: 90%;
  margin-inline: auto;
  margin-block: 64px;
  justify-content: space-between;
  display: flex;
  flex-direction: column;
}

.blog-template-article {
  font-family: "Inter", sans-serif;
  line-height: 30px;
  width: 100%;
}

.blog-template-sidebar {
  width: 100%;
}

.blog-template-social-media-container {
  display: flex;
  flex-direction: column;
}

.blog-template-social-media-icons-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 16px;
}

.blog-template-social-media-icon {
  height: 20px;
  font-weight: 500;
  width: auto;
  color: black;
}

.blog-template-social-media-icon:hover {
  color: rgb(60, 60, 60);
  cursor: pointer;
}

@media (min-width: 1024px) {
  .blog-template-content-container {
    display: flex;
    flex-direction: row;
  }

  .blog-template-article {
    width: 75%;
  }

  .blog-template-sidebar {
    width: 20%;
  }
}

/* BlogTemplate.js Blog Carousel Cards */
.other-blogs-container {
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin-block: 32px;
  margin-left: 5%;
  width: 90%;
}

.other-blogs-heading {
  font-family: "Inter", sans-serif;
}

#blog-template.carousel-container .carousel-buttons {
  top: -24px;
}

.blog-card {
  box-shadow: 4px 2px 12px 4px rgba(20, 20, 43, 0.08);
  border-radius: 8px;
  height: clamp(350px, 90vh, 450px);
  width: clamp(250px, 30vw, 350px); /* Adjusted */
  margin-block: 12px;
  transition: transform 0.3s ease;
  overflow: hidden;
  margin-inline: 12px;
}

.blog-card:hover {
  transform: translateY(-4px);
  cursor: pointer;
}

.blog-card > * {
  font-family: "Inter", sans-serif;
}

.blog-card-content {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  gap: 0;
}

.blog-card-content {
  margin: 0;
  justify-content: space-between;
}

.blog-card-content > img {
  height: 40%;
  object-fit: cover;
  width: 100%;
}

.blog-card-text-content {
  display: flex;
  flex-direction: column;
  height: 55%;
  gap: 8px;
}

.blog-card-text-content > * {
  margin: 0;
  width: 90%;
  margin: auto;
}

.blog-card-title {
  text-justify: center;
  font-size: 1.2rem;
}

.blog-card-subtitle {
  flex-shrink: 0;
  flex-grow: 2;
  font-size: 0.85rem;
  line-height: 20px;
}

.blog-card-author-container {
  display: flex;
  gap: 8px;
  justify-self: flex-end;
  align-items: center;
}

.blog-card-author-container > img {
  width: 24px;
  height: 24px;
  border-radius: 100%;
}

.blog-card-author-date {
  display: flex;
  gap: 8px;
  align-items: center;
}

.blog-card-author-name {
  color: #6c757d;
  font-weight: 400;
  font-size: 0.8rem;
}

.blog-card-dot {
  font-size: 0.8rem;
  color: #6c757d;
}

.blog-card-date {
  font-size: 0.8rem;
  color: #6c757d;
}

/* TermsAndConditions.js& PrivacyPolicy.js */
.t-and-c-container,
.privacy-policy-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.t-and-c-content,
.privacy-policy-content {
  margin-inline: auto;
  margin-block: 128px;
  display: flex;
  flex-direction: column;
  width: 90%;
  max-width: 1000px;
  gap: 24px;
  font-family: "Inter", "DM Sans", sans-serif;
}

.t-and-c-content *,
.privacy-policy-content * {
  margin: 0;
}

.t-and-c-content h1,
.privacy-policy-content h1 {
  margin-inline: auto;
  margin-block: 16px;
  font-size: 1.5rem;
}

.t-and-c-content .text-block,
.privacy-policy-content .text-block {
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.t-and-c-content .text-block h4,
.privacy-policy-content .text-block h4 {
  margin-block: 0;
  font-size: 1.1rem;
}

.t-and-c-content .text-block p,
.privacy-policy-content .text-block p {
  margin-block: 0;
  font-size: 1rem;
  line-height: 24px;
}

.t-and-c-content .text-block-link {
  color: #3081ee;
  text-decoration: none;
}

.privacy-policy-content .text-block ol {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.privacy-policy-content .text-block ul {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.privacy-policy-content .text-block ol li,
.privacy-policy-content .text-block ul li {
  line-height: 24px;
}

.privacy-policy-content .text-block span {
  text-decoration: underline;
  text-decoration-color: #3081ee;
  color: #3081ee;
}
